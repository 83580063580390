<template>
  <div
    :id="boardId"
    ref="root"
    class="relative"
    :style="{
      width: `${COLUMN_WIDTH * columns}px`,
    }"
  >
    <div
      v-for="connection in connections"
      :key="`${connection.button.uuid}->${connection.target.uuid}`"
      class="absolute inset-0 pointer-events-none"
    >
      <BoardCurve
        class="board-connection-curve"
        :board-id="boardId"
        :highlight-id="connection.button.uuid"
        :connection-start-id="`board-connection-point-${connection.button.uuid}`"
        :connection-end-id="`board-connection-point-${connection.target.uuid}`"
        @click="onDisconnect(connection)"
      />
    </div>
    <template v-if="isConnecting && partialConnectionStart">
      <div class="absolute inset-0 pointer-events-none">
        <SmoothCurve
          class="text-twitter-blue"
          :start="partialConnectionStart"
          :end="partialConnectionEnd"
        />
      </div>
    </template>
    <grid-layout
      v-model:layout="layout"
      :col-num="columns"
      :row-height="ROW_HEIGHT"
      :is-draggable="true"
      :is-resizable="false"
      :prevent-collision="true"
      :vertical-compact="false"
      :use-css-transforms="true"
      :margin="[COLUMN_MARGIN, ROW_MARGIN]"
      :auto-size="true"
    >
      <BoardItem
        v-for="(data, index) in layout"
        :key="data.i"
        v-model="layout[index]"
        :layout="layout"
      >
        <CardBoardView
          v-model="scenario"
          :card="scenario[index]"
        />
      </BoardItem>
    </grid-layout>
  </div>
</template>

<script lang="ts">
import { templateRef, useVModel } from '@vueuse/core';
import _ from 'lodash';
import { computed, defineComponent, PropType, readonly, ref } from 'vue-demi';
import { GridLayoutData } from 'vue-grid-layout';

import { Card } from '@/api/models/card';
import { Scenario, ScenarioConnection } from '@/api/models/scenario';
import { COLUMN_MARGIN, COLUMN_WIDTH, ROW_HEIGHT, ROW_MARGIN } from '@/components/Board';
import BoardCurve from '@/components/BoardCurve.vue';
import BoardItem from '@/components/BoardItem.vue';
import CardBoardView from '@/components/CardBoardView.vue';
import SmoothCurve from '@/components/SmoothCurve.vue';
import { useBoardConnection } from '@/hooks/use_board_connection';
import { useHighlightDirectedGraph } from '@/hooks/use_highlight';
import { useElementPositionInElement, useMousePositionInElement } from '@/hooks/use_position_in_element';

export default defineComponent({
  name: 'Board',
  components: {
    BoardItem,
    BoardCurve,
    SmoothCurve,
    CardBoardView,
  },
  props: {

    /**
     * 操作するシナリオ
     */
    modelValue: {
      type: Object as PropType<Scenario>,
      required: true,
    },

    /**
     * コラム数
     */
    columns: {
      type: Number,
      default: 40,
    },
  },
  emits: [
    'update:modelValue',
  ],
  setup(props, { emit }) {
    const boardId = readonly(ref(_.uniqueId('board')));
    const root = templateRef<HTMLDivElement>('root');
    const scenario = useVModel(props, 'modelValue', emit);
    const layout = computed<GridLayoutData>({
      get() {
        return scenario.value.map(card => {
          return {
            i: card.uuid,
            x: card.editAreaXCoordinate,
            y: card.editAreaYCoordinate,
            w: 2,
            h: 1,
          };
        });
      },
      set(data) {
        const itemsById = _.keyBy(data, item => item.i);

        for (const card of scenario.value as Card[]) {
          const item = itemsById[card.uuid];

          card.mutate({
            editAreaXCoordinate: item.x,
            editAreaYCoordinate: item.y,
          });
        }
      },
    });

    //
    // 接続の定義、作成、と削除
    //

    const connections = computed(() => scenario.value.getConnections());

    function onConnect(connection: ScenarioConnection) {
      connection.connect();
    }

    function onDisconnect(connection: ScenarioConnection) {
      connection.disconnect();
    }

    const { isConnecting, partialConnection } = useBoardConnection(onConnect);
    const partialConnectionStartId = computed(() => {
      const currentPartialConnection = partialConnection.value;

      if (!currentPartialConnection) {
        return null;
      }

      return `board-connection-point-${currentPartialConnection.button.uuid}`;
    });

    const partialConnectionStart = useElementPositionInElement(partialConnectionStartId, root);
    const partialConnectionEnd = useMousePositionInElement(root);

    //
    // ホバーする時のハイライト
    //

    const highlightConnections = computed(() => {
      return connections.value.flatMap(({ source, target, button }) => {
        return [
          {
            start: source.uuid,
            end: button.uuid,
          },
          {
            start: button.uuid,
            end: target.uuid,
          },
        ];
      });
    });

    useHighlightDirectedGraph(highlightConnections);

    return {
      COLUMN_WIDTH,
      ROW_HEIGHT,
      COLUMN_MARGIN,
      ROW_MARGIN,
      boardId,
      layout,
      scenario,
      connections,
      onDisconnect,
      isConnecting,
      partialConnectionStart,
      partialConnectionEnd,
    };
  },
});
</script>

<style lang="scss">
// グリッドの後ろで線にポインターイベントに反応してもらいたい。
// でも、グリッドがデフォルトで背景にイベントを渡さない。
// グリッドアイテムが壊れないように気を付けながら後ろの要素にイベントを渡す。
.vue-grid-layout {
  pointer-events: none;

  .vue-grid-item {
    pointer-events: all;
  }
}

.vue-grid-item {
  &.vue-grid-placeholder {
    background: theme('colors.blue.shadow') !important;
    border-radius: theme('borderRadius.lg') !important;
    opacity: 0.75 !important;
  }
}

.board-connection-curve {
  cursor: url('../assets/cut.png'), auto;
}
</style>
