<template>
  <div
    class="select-none"
    @drop="onConnect()"
    @dragover.prevent
  >
    <uv-card :variant="variant">
      <template #title>
        <div class="flex items-center">
          <BoardConnectionPoint :connection-id="card.uuid" />
          <div class="text-white font-bold flex flex-row items-center space-x-2 px-3 py-2">
            <uv-icon
              class="flex-shrink-0"
              :icon="icon"
              :size="15"
              :style="{
                marginBottom: '1px',
              }"
            />
            <div class="h-6 line-clamp-1 break-all">
              {{ card.cardNm }}
            </div>
          </div>
        </div>
      </template>
      <div
        class="board-card-source cursor-pointer"
        @click.stop="$emit('click')"
      >
        <slot />
      </div>
    </uv-card>
  </div>
</template>

<script lang="ts">
import { UvCard, UvIcon } from '@uniquevision/beluga-ui';
import { computed, defineComponent, PropType } from 'vue';

import { Card } from '@/api/models/card';
import BoardConnectionPoint from '@/components/BoardConnectionPoint.vue';
import { useCurrentBoardConnection } from '@/hooks/use_board_connection';
import { Kbns } from '@/utilities/kbn';

export default defineComponent({
  name: 'CardBoardFrame',
  components: {
    UvCard,
    UvIcon,
    BoardConnectionPoint,
  },
  props: {

    /**
     * 表示されるカード
     */
    card: {
      type: Object as PropType<Card>,
      required: true,
    },

    /**
     * このカードが何かと接続しているかどうか
     */
    connected: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'click',
  ],
  setup(props) {
    const variant = computed(() => {
      let variant = 'gray';

      if (props.connected) {
        variant = 'secondary';
      }

      return variant;
    });

    const icon = computed(() => {
      switch (props.card.cardKbn) {
        case Kbns.CARD_KBN.PAGE:
          return 'list';

        case Kbns.CARD_KBN.TWEET:
          return 'share';

        default:
          return 'list';
      }
    });

    const { onEnd } = useCurrentBoardConnection();

    function onConnect() {
      onEnd(props.card);
    }

    return {
      variant,
      icon,
      onConnect,
    };
  },
});
</script>
