import { v4 as uuidv4 } from 'uuid';

import TransformTimestampToDate from '@/decorators/transform_timestamp_to_date';
import { registerGenerator } from '@/utilities/generate';
import { Model } from '@/utilities/model';

export class Media extends Model<Media> {
  uuid = uuidv4();

  @TransformTimestampToDate
  createdAt = new Date(0);

  byteCount = 0;

  mimeTypeCd = '';

  fileNm = '';

  url = '';

  // TODO: シナリオに入っている。中野さんに付けるように依頼する。
  videoAutoplayFlag = false;

  // TODO: シナリオに入っている。中野さんに付けるように依頼する。
  videoControlsFlag = false;

  download() {
    const link = document.createElement('a');
    link.target = '_blank';
    link.href = this.url;
    link.download = this.fileNm;
    link.click();
  }
}

export class LocalMedia extends Media {
  constructor(private readonly file: File) {
    super();
    this.createdAt = new Date();
    this.byteCount = file.size,
    this.mimeTypeCd = file.type;
    this.fileNm = file.name;
    this.url = URL.createObjectURL(this.file);
  }
}

registerGenerator(
  Media,
  ({ now }) => new Media().mutate({
    createdAt: now,
    byteCount: 4117,
    mimeTypeCd: 'png',
    fileNm: 'flower.png',
    url: 'https://images.unsplash.com/photo-1444021465936-c6ca81d39b84?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=880&q=80',
  }),
);
