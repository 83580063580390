<template>
  <div class="relative break-all">
    <!--
      この要素にQuillを付ける。Quillが親（！）にツールバーを
      勝手に入れてしまう。このコンポーネントが消えるタイミングで
      ツールバーも消えるように、更にdivで囲んでいる。
    -->
    <div :id="editorId" />
  </div>
</template>

<script lang="ts">
/* eslint-disable object-curly-newline */
import _ from 'lodash';
import Quill from 'quill';
import { computed, defineComponent, onMounted } from 'vue-demi';

const AlignStyle = Quill.import('attributors/style/align');
Quill.register(AlignStyle, true);

const BackgroundStyle = Quill.import('attributors/style/background');
Quill.register(BackgroundStyle, true);

const ColorStyle = Quill.import('attributors/style/color');
Quill.register(ColorStyle, true);

const SIZE_OPTIONS = ['10px', '14px', '18px', '24px'];
const SizeStyle = Quill.import('attributors/style/size');
SizeStyle['whitelist'] = SIZE_OPTIONS;
Quill.register(SizeStyle, true);

/**
 * スタイルを含めてテキストを入力するコンポーネント。
 *
 * 内容は`v-html`でレンダーしなければならない。
 */
export default defineComponent({
  name: 'RichTextInput',
  props: {

    /**
     * 入力内容
     *
     * ※ 初期値しか使われない。
     */
    modelValue: {
      type: String,
      required: true,
    },

    /**
     * 入力がない時のメッセージ
     *
     * ※ 初期値しか使われない。
     */
    placeholder: {
      type: String,
      default: undefined,
    },
  },
  emits: [

    /**
     * v-model用
     */
    'update:modelValue',
  ],
  setup(props, { emit }) {
    const editorId = computed(() => _.uniqueId('quill'));

    onMounted(() => {
      // `ref`が渡せないからIDを教えるしかない。とするとVueが要素を変える時
      // Quillが新しい参照を使ってくれる。（直接`editor`を渡して見ればエラーが出る！）
      const quill = new Quill(`#${editorId.value}`, {
        theme: 'snow',
        placeholder: props.placeholder,
        modules: {
          toolbar: {
            container: [
              [{ size: SIZE_OPTIONS }, 'bold', 'italic', 'underline', { align: [] }],
              [{ color: [] }, { background: [] }, 'clean'],
            ],
          },
        },
      });

      const onTextChange = _.debounce(() => {
        emit('update:modelValue', quill.root.innerHTML);
      }, 100);

      quill.root.innerHTML = props.modelValue;
      quill.on('text-change', onTextChange);
    });

    return {
      editorId,
    };
  },
});
</script>

<style lang="scss">
@import '~quill/dist/quill.snow.css';

.ql-snow {
  &.ql-toolbar {
    border-top-left-radius: theme('borderRadius.default');
    border-top-right-radius: theme('borderRadius.default');
  }

  &.ql-container {
    border-bottom-left-radius: theme('borderRadius.default');
    border-bottom-right-radius: theme('borderRadius.default');
  }
}

// Quillが吹き出しを出す時、隣の要素より上で映るように。
.ql-tooltip {
  z-index: 1;
}

// Quillが大変！カスタムなフォントサイズを設定してもどれもnormalとして映る。
// 設定されたサイズが出るよう、勝手にCSSをいじってしまう必要があるらしい。
// ソース：https://stackoverflow.com/a/64827265
// 取得日時：2021年10月25日
.ql-snow {
  .ql-picker {
    &.ql-size {
      .ql-picker-label,
      .ql-picker-item {
        &::before {
            content: attr(data-value) !important;
        }
      }
    }
  }
}
</style>
