<template>
  <uv-select v-model="model">
    <template #options>
      <uv-select-option :value="$kbn.BUTTON_KBN.CLICK">
        選択肢
      </uv-select-option>
      <uv-select-option :value="$kbn.BUTTON_KBN.URL">
        外部URL
      </uv-select-option>
    </template>
  </uv-select>
</template>

<script lang="ts">
import { UvSelect, UvSelectOption } from '@uniquevision/beluga-ui';
import { useVModel } from '@vueuse/core';
import { defineComponent, PropType } from 'vue-demi';

import { Kbn } from '@/utilities/kbn';

export default defineComponent({
  name: 'ButtonTypeSelect',
  components: {
    UvSelect,
    UvSelectOption,
  },
  props: {
    kbn: {
      type: String as PropType<Kbn<'BUTTON_KBN'>>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const model = useVModel(props, 'kbn', emit);

    return {
      model,
    };
  },
});
</script>
