
import { UvButton, UvCard } from '@uniquevision/beluga-ui';
import { defineComponent } from 'vue-demi';

export default defineComponent({
  name: 'ConfirmationDialog',
  components: {
    UvCard,
    UvButton,
  },
  emits: [
    'delete',
    'cancel',
  ],
});
