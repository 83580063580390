
import { UvIcon, UvIconButton, UvLoading } from '@uniquevision/beluga-ui';
import { templateRef } from '@vueuse/core';
import { defineComponent, PropType, ref } from 'vue-demi';

import { Media } from '@/api/models/media';
import MediaPreview from '@/components/MediaPreview.vue';
import { useApi } from '@/hooks/use_api';

type State =
  'view' |
  'busy';

export default defineComponent({
  name: 'MediaUploader',
  components: {
    UvIcon,
    UvIconButton,
    UvLoading,
    MediaPreview,
  },
  inheritAttrs: false,
  props: {

    /**
     * メディアの種類
     */
    type: {
      type: String as PropType<'image' | 'video'>,
      required: true,
    },

    /**
     * v-model用
     */
    media: {
      type: Object as PropType<Media | null>,
      default: null,
    },
  },
  emits: [
    'update:media',
  ],
  setup(props, { emit }) {
    const api = useApi();
    const inputEl = templateRef<HTMLInputElement>('input');
    const state = ref<State>('view');

    function onDownload() {
      props.media?.download();
    }

    function onDelete() {
      emit('update:media', null);
    }

    function onPickFile() {
      inputEl.value?.click();
    }

    async function onPickedFile() {
      const currentInputEl = inputEl.value;

      if (!currentInputEl) {
        return;
      }

      const files = currentInputEl.files ?? [];

      if (files.length === 0) {
        return;
      }

      const file = files[0];
      currentInputEl.value = '';

      try {
        state.value = 'busy';
        const media = await api.postMedia(file);

        if (media) {
          emit('update:media', media);
        }
      } finally {
        state.value = 'view';
      }
    }

    return {
      onPickFile,
      onPickedFile,
      onDownload,
      onDelete,
      state,
    };
  },
});
