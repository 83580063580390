import _ from 'lodash';
import { inject, InjectionKey, provide, readonly, ref } from 'vue-demi';

const ERRORS_INJECTION_KEY: InjectionKey<ErrorContext> = Symbol('error');

export function useErrors() {
  const errors = ref<string[]>([]);

  /**
  * 新しいエラーをこの文脈に報告する。
  *
  * @param error エラー内容
  */
  function reportError(error: string) {
    errors.value.push(error);
  }

  /**
   * フォームのエラーをこの文脈に報告する。
   *
   * @param errors フォームのエラー内容
   */
  function reportFormErrors(errors: {
    form: string[]
    fields: Partial<Record<string, string>>
  }) {
    for (const error of errors.form) {
      reportError(error);
    }

    for (const error of Object.values(errors.fields)) {
      reportError(error!);
    }
  }

  /**
   * 今溜まっているエラーをクリアする。
   */
  function clearErrors() {
    errors.value = [];
  }

  const context = {
    errors: readonly(errors),
    reportError,
    reportFormErrors,
    clearErrors,
  };

  provide(ERRORS_INJECTION_KEY, context);

  return context;
}

export function useCurrentErrors() {
  return inject(ERRORS_INJECTION_KEY, {
    errors: readonly(ref<string[]>([])),
    reportError: _.noop,
    reportFormErrors: _.noop,
    clearErrors: _.noop,
  });
}

export type ErrorContext = ReturnType<typeof useErrors>;
