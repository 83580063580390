
import { UvIcon } from '@uniquevision/beluga-ui';
import { defineComponent, reactive } from 'vue-demi';

import { Campaign } from '@/api/models/campaign';
import AddPageBoardCardButton from '@/components/AddPageBoardCardButton.vue';
import AddTweetBoardCardButton from '@/components/AddTweetBoardCardButton.vue';
import Board from '@/components/Board.vue';
import { useMockApi } from '@/hooks/use_api';

export default defineComponent({
  name: 'CampaignScenarioPage',
  components: {
    UvIcon,
    Board,
    AddPageBoardCardButton,
    AddTweetBoardCardButton,
  },
  setup() {
    useMockApi();
    const campaign = reactive(new Campaign());

    return {
      campaign,
    };
  },
});
