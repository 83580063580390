
import { defineComponent, PropType } from 'vue';

import { Button } from '@/api/models/button';
import { Card } from '@/api/models/card';
import { useCurrentBoardConnection } from '@/hooks/use_board_connection';

export default defineComponent({
  name: 'BoardConnectionSource',
  props: {
    card: {
      type: Object as PropType<Card>,
      required: true,
    },
    button: {
      type: Object as PropType<Button>,
      required: true,
    },
  },
  setup() {
    const { onStart, onCancel } = useCurrentBoardConnection();

    function onSetupConnection(event: DragEvent) {
      // 仕様：ドラッグする時、要素の影を表示しない。
      event.dataTransfer?.setDragImage(document.createElement('div'), 0, 0);
    }

    return {
      onStart,
      onCancel,
      onSetupConnection,
    };
  },
});
