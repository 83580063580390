
import { UvIcon } from '@uniquevision/beluga-ui';
import { defineComponent, PropType } from 'vue';

import { Button } from '@/api/models/button';
import { Card } from '@/api/models/card';
import BoardConnectionPoint from '@/components/BoardConnectionPoint.vue';
import BoardConnectionSource from '@/components/BoardConnectionSource.vue';
import HighlightArea from '@/components/HighlightArea.vue';

/**
 * `Button`の`Board`上の表示。
 */
export default defineComponent({
  name: 'ButtonBoardView',
  components: {
    UvIcon,
    HighlightArea,
    BoardConnectionPoint,
    BoardConnectionSource,
  },
  props: {

    /**
     * ボタンが入っているカード
     */
    card: {
      type: Object as PropType<Card>,
      required: true,
    },

    /**
     * 表示するボタン
     */
    button: {
      type: Object as PropType<Button>,
      required: true,
    },
  },
});
