import { Transform } from 'class-transformer';
import { isValid, parseJSON } from 'date-fns';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function TransformTimestampToDate(target: any, propertyKey: string) {
  Transform(({ value }) => value && parseJSON(value), {
    toClassOnly: true,
  })(target, propertyKey);

  Transform(({ value }) => isValid(value) && (value as Date).getUTCMilliseconds(), {
    toPlainOnly: true,
  })(target, propertyKey);
}
