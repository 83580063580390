<template>
  <uv-card>
    <div class="space-y-5">
      <div class="text-lg">
        <slot />
      </div>
      <div class="flex items-center justify-center space-x-5">
        <uv-button
          class="w-40 text-lg"
          @click="$emit('delete')"
        >
          削除する
        </uv-button>
        <uv-button
          class="w-40 text-lg"
          variant="white"
          @click="$emit('cancel')"
        >
          キャンセル
        </uv-button>
      </div>
    </div>
  </uv-card>
</template>

<script lang="ts">
import { UvButton, UvCard } from '@uniquevision/beluga-ui';
import { defineComponent } from 'vue-demi';

export default defineComponent({
  name: 'ConfirmationDialog',
  components: {
    UvCard,
    UvButton,
  },
  emits: [
    'delete',
    'cancel',
  ],
});
</script>
