
import { UvSelect, UvSelectOption } from '@uniquevision/beluga-ui';
import { useVModel } from '@vueuse/core';
import { defineComponent, PropType } from 'vue-demi';

import { Kbn } from '@/utilities/kbn';

export default defineComponent({
  name: 'ButtonTypeSelect',
  components: {
    UvSelect,
    UvSelectOption,
  },
  props: {
    kbn: {
      type: String as PropType<Kbn<'BUTTON_KBN'>>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const model = useVModel(props, 'kbn', emit);

    return {
      model,
    };
  },
});
