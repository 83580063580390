<template>
  <CardPreviewFrame>
    <div class="whitespace-pre-line break-all">
      {{ card.tweetContent }}
    </div>
    <template v-if="card.isLinkGenerated">
      <TwitterCardPreview
        :kbn="card.twitterCardLayoutKbn"
        :title="card.twitterCardTitleContent"
        :description="card.twitterCardDescriptionContent"
        :media="card.twitterCardMedia"
      />
    </template>
    <template v-else>
      <div class="flex flex-col items-center justify-center rounded-lg border-2 border-solid border-gray-light bg-gray-lightest h-40 p-5 space-y-1">
        <p>
          ここにOGが表示されます。
        </p>
        <p class="text-xs">
          ※実際のプレビューは投稿してご確認ください
        </p>
      </div>
    </template>
  </CardPreviewFrame>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue-demi';

import { TweetCard } from '@/api/models/card';
import CardPreviewFrame from '@/components/CardPreviewFrame.vue';
import TwitterCardPreview from '@/components/TwitterCardPreview.vue';

export default defineComponent({
  name: 'TweetCardPreview',
  components: {
    CardPreviewFrame,
    TwitterCardPreview,
  },
  props: {

    /**
     * プレビューで表示するカード
     */
    card: {
      type: Object as PropType<TweetCard>,
      required: true,
    },
  },
});
</script>
