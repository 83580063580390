import { Exclude, Expose } from 'class-transformer';
import * as zod from 'zod';

import { TweetCard } from '@/api/models/card';
import { Media } from '@/api/models/media';
import { Form } from '@/utilities/form';
import { Kbn, Kbns } from '@/utilities/kbn';

class Fields {
  readonly cardKbn: Kbn<'CARD_KBN'> = Kbns.CARD_KBN.TWEET;

  cardNm = '';

  editAreaXCoordinate = 0;

  editAreaYCoordinate = 0;

  tweetContent = '';

  tweetContentLinkKbn: Kbn<'TWEET_CONTENT_LINK_KBN'> = Kbns.TWEET_CONTENT_LINK_KBN.GENERATED;

  twitterCardLayoutKbn: Kbn<'TWITTER_CARD_LAYOUT_KBN'> = Kbns.TWITTER_CARD_LAYOUT_KBN.SUMMARY_LARGE_IMAGE;

  twitterCardTitleContent = '';

  @Exclude()
  twitterCardMedia: Media | null = null;

  @Expose()
  get twitterCardMediaUuid() {
    return this.twitterCardMedia?.uuid;
  }

  twitterCardDescriptionContent = '';

  twitterCardImageAltContent = '';

  twitterCardHtmlFileCode = '';

  constructor(card?: TweetCard) {
    if (card) {
      this.cardNm = card.cardNm;
      this.editAreaXCoordinate = card.editAreaXCoordinate;
      this.editAreaYCoordinate = card.editAreaYCoordinate;
      this.tweetContent = card.tweetContent;
      this.tweetContentLinkKbn = card.tweetContentLinkKbn;
      this.twitterCardLayoutKbn = card.twitterCardLayoutKbn;
      this.twitterCardTitleContent = card.twitterCardTitleContent;
      this.twitterCardMedia = card.twitterCardMedia;
      this.twitterCardDescriptionContent = card.twitterCardDescriptionContent;
      this.twitterCardImageAltContent = card.twitterCardImageAltContent;
      this.twitterCardHtmlFileCode = card.twitterCardHtmlFileCode;
    }
  }
}

export class TweetCardForm extends Form<Fields> {
  constructor(readonly original?: TweetCard) {
    super();

    if (original) {
      this.fields = new Fields(original);
    }
  }

  readonly fields = new Fields();

  readonly schema: zod.Schema<Partial<Fields>> = zod.object({
    cardKbn: zod.nativeEnum(Kbns.CARD_KBN),
    cardNm: zod.string()
      .nonempty('カード名を指定してください。')
      .max(32),
    editAreaXCoordinate: zod.number(),
    editAreaYCoordinate: zod.number(),
    tweetContent: zod.string(),
    tweetContentLinkKbn: zod.nativeEnum(Kbns.TWEET_CONTENT_LINK_KBN),
    twitterCardLayoutKbn: zod.nativeEnum(Kbns.TWITTER_CARD_LAYOUT_KBN),
    twitterCardTitleContent: zod.string(),
    twitterCardDescriptionContent: zod.string(),
    twitterCardImageAltContent: zod.string(),
    twitterCardHtmlFileCode: zod.string(),
  });

  get card() {
    return new TweetCard().mutate({
      uuid: this.original?.uuid,
      campaignUuid: this.original?.uuid,
      cardKbn: this.fields.cardKbn,
      cardNm: this.fields.cardNm,
      editAreaXCoordinate: this.fields.editAreaXCoordinate,
      editAreaYCoordinate: this.fields.editAreaYCoordinate,
      tweetContent: this.fields.tweetContent,
      tweetContentLinkKbn: this.fields.tweetContentLinkKbn,
      twitterCardLayoutKbn: this.fields.twitterCardLayoutKbn,
      twitterCardTitleContent: this.fields.twitterCardTitleContent,
      twitterCardMedia: this.fields.twitterCardMedia,
      twitterCardDescriptionContent: this.fields.twitterCardDescriptionContent,
      twitterCardImageAltContent: this.fields.twitterCardImageAltContent,
      twitterCardHtmlFileCode: this.fields.twitterCardHtmlFileCode,
    });
  }
}
