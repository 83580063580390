<template>
  <CardFormDialog
    :card="card"
    @submit="onValidateAndSubmit(form)"
    @delete="onDelete"
    @cancel="onCancel"
  >
    <div class="flex space-x-4">
      <div class="space-y-4">
        <TweetCardPreview :card="form.card" />
        <div class="text-sm">
          ※プレビューはイメージです。調整は入稿完了も可能です。
        </div>
      </div>
      <div class="flex-grow">
        <CardFormFieldBox>
          <CardFormFieldRow title="カード名">
            <uv-text-input
              v-model="form.fields.cardNm"
              :maxlength="32"
            />
          </CardFormFieldRow>
          <CardFormFieldRow title="URL作成">
            <TweetContentLinkSelect v-model="form.fields.tweetContentLinkKbn" />
          </CardFormFieldRow>
          <template v-if="form.card.isLinkGenerated">
            <CardFormFieldRow title="タイプ">
              <div class="flex flex-col space-y-2">
                <TwitterCardLayoutSelect v-model="form.fields.twitterCardLayoutKbn" />
                <LinkText class="self-end">
                  カードタイプ・OGPについて
                </LinkText>
              </div>
            </CardFormFieldRow>
          </template>
          <CardFormFieldDelimiter />
          <CardFormFieldRow title="テキスト" subtitle="（100文字）">
            <uv-text-area
              v-model="form.fields.tweetContent"
              :maxlength="100"
            />
            <p>
              ※OGPの表示が正しくされないため、<br />
              他にURLは入れないようにお願い致します。
            </p>
          </CardFormFieldRow>
          <template v-if="form.card.isLinkGenerated">
            <CardFormFieldRow title="メディア">
              <InlineMediaUploader
                v-model:media="form.fields.twitterCardMedia"
              />
            </CardFormFieldRow>
            <CardFormFieldRow title="タイトル">
              <uv-text-input
                v-model="form.fields.twitterCardTitleContent"
                :maxlength="32"
              />
            </CardFormFieldRow>
            <CardFormFieldRow title="説明文">
              <uv-text-area
                v-model="form.fields.twitterCardDescriptionContent"
                :maxlength="64"
              />
            </CardFormFieldRow>
          </template>
        </CardFormFieldBox>
      </div>
    </div>
  </CardFormDialog>
</template>

<script lang="ts">
import { UvTextArea, UvTextInput } from '@uniquevision/beluga-ui';
import _ from 'lodash';
import { defineComponent, PropType, ref } from 'vue-demi';

import { TweetCardForm } from '@/api/forms/tweet_card_form';
import { TweetCard } from '@/api/models/card';
import CardFormDialog from '@/components/CardFormDialog.vue';
import CardFormFieldBox from '@/components/CardFormFieldBox.vue';
import CardFormFieldDelimiter from '@/components/CardFormFieldDelimiter.vue';
import CardFormFieldRow from '@/components/CardFormFieldRow.vue';
import InlineMediaUploader from '@/components/InlineMediaUploader.vue';
import LinkText from '@/components/LinkText.vue';
import TweetCardPreview from '@/components/TweetCardPreview.vue';
import TweetContentLinkSelect from '@/components/TweetContentLinkSelect.vue';
import TwitterCardLayoutSelect from '@/components/TwitterCardLayoutSelect.vue';
import { useErrors } from '@/hooks/use_errors';

export default defineComponent({
  name: 'TweetCardFormDialog',
  components: {
    UvTextArea,
    UvTextInput,
    CardFormDialog,
    CardFormFieldBox,
    CardFormFieldDelimiter,
    CardFormFieldRow,
    InlineMediaUploader,
    TweetCardPreview,
    TweetContentLinkSelect,
    TwitterCardLayoutSelect,
    LinkText,
  },
  props: {

    /**
     * 編集するカード
     */
    card: {
      type: Object as PropType<TweetCard>,
      required: false,
      default: undefined,
    },

    /**
     * 登録する時
     */
    onSubmit: {
      type: Function as PropType<(form: TweetCardForm) => Promise<void>>,
      default: () => _.noop,
    },

    /**
     * 削除する時
     */
    onDelete: {
      type: Function as PropType<() => Promise<void>>,
      default: () => _.noop,
    },

    /**
     * キャンセルする時
     */
    onCancel: {
      type: Function as PropType<() => Promise<void>>,
      default: () => _.noop,
    },
  },
  setup(props) {
    const { clearErrors, reportFormErrors } = useErrors();
    const form = ref(new TweetCardForm(props.card));

    function onValidateAndSubmit(form: TweetCardForm) {
      clearErrors();
      const success = form.validate();

      if (success) {
        props.onSubmit(form);
      } else {
        reportFormErrors(form.latestError);
      }
    }

    return {
      form,
      onValidateAndSubmit,
    };
  },
});
</script>
