<template>
  <div
    class="rounded-lg shadow-small space-y-4 p-6"
    :style="{ width: '276px' }"
  >
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue-demi';

export default defineComponent({
  name: 'CardPreviewFrame',
});
</script>
