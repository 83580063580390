import { App } from 'vue';

export const Kbns = {

  // キャンペーン状態区分
  CAMPAIGN_STATUS_KBN: {
    NEW: '01001', // 未入力
    IN_PROGRESS: '01002', // 入力中
    SUBMISSION_CONFIRMING: '01003', // 入稿確認中
    AWAITING_START: '01004', // 開始待ち
    COMPLETE: '01005', // 完了
  },

  // カード区分
  CARD_KBN: {
    PAGE: '02001', // ページ
    TWEET: '02002', // ツイート
  },

  // ページカード配置区分
  PAGE_CARD_LAYOUT_KBN: {
    IMAGE_BUTTON_SINGLE: '03001', // 画像とボタン
    VIDEO_BUTTON_SINGLE: '03002', // 動画とボタン
  },

  // ツイート内容リンク区分
  TWEET_CONTENT_LINK_KBN: {
    GENERATED: '04001', // 生成
    CUSTOM: '04002', // カスタム
    NONE: '04003', // 不使用
  },

  // ボタン区分
  BUTTON_KBN: {
    CLICK: '05001', // クリック
    URL: '05002', // URL
  },

  // ツイッターカードレイアウト区分
  TWITTER_CARD_LAYOUT_KBN: {
    SUMMARY_LARGE_IMAGE: '06001', // summary_large_image
    SUMMARY: '06002', // summary
  },
} as const;

export type Kbn<T extends keyof typeof Kbns> = (typeof Kbns)[T][keyof ((typeof Kbns)[T])];

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $kbn: typeof Kbns;
  }
}

export function installKbns(app: App<unknown>) {
  app.config.globalProperties.$kbn = Kbns;
}
