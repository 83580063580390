<template>
  <div class="h-full flex flex-col justify-center items-center">
    <template v-if="media">
      <template v-if="error">
        <div class="flex items-center justify-center w-full h-full p-3">
          <uv-icon
            class="text-danger"
            icon="cancel"
            :size="36"
          />
        </div>
      </template>
      <template v-else>
        <img
          v-if="type === 'image'"
          v-bind="$attrs"
          class="object-cover w-full h-full"
          :src="media.url"
          @error="error = true"
        />
        <video
          v-else-if="type === 'video'"
          v-bind="$attrs"
          class="object-cover w-full"
          :autoplay="media.videoAutoplayFlag"
          :controls="media.videoControlsFlag"
        >
          <source
            :src="media.url"
            @error="error = true"
          />
        </video>
      </template>
    </template>
    <template v-else>
      <div
        class="flex items-center justify-center bg-gray-lightest w-full h-full p-6"
        v-bind="$attrs"
      >
        <uv-icon
          class="text-gray-default"
          icon="cancel"
          :size="36"
        />
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { UvIcon } from '@uniquevision/beluga-ui';
import { defineComponent, PropType, ref, watch } from 'vue-demi';

import { Media } from '@/api/models/media';

export default defineComponent({
  name: 'MediaPreview',
  components: {
    UvIcon,
  },
  inheritAttrs: false,
  props: {

    /**
     * メディアの種類
     */
    type: {
      type: String as PropType<'image' | 'video'>,
      required: true,
    },

    /**
     * 表示するメディア
     */
    media: {
      type: Object as PropType<Media | null>,
      default: null,
    },
  },
  setup(props) {
    const error = ref(false);

    watch(() => props.media, () => {
      error.value = false;
    }, {
      deep: true,
    });

    return {
      error,
    };
  },
});
</script>
