import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { registerGenerator } from '@/utilities/generate';
import { Kbn, Kbns } from '@/utilities/kbn';
import { Model } from '@/utilities/model';

import { Button } from './button';
import { Media } from './media';

export abstract class Card<T = unknown> extends Model<T> {
  uuid = uuidv4();

  campaignUuid = '';

  cardNm = '';

  editAreaXCoordinate = 0;

  editAreaYCoordinate = 0;

  abstract readonly cardKbn: Kbn<'CARD_KBN'>;

  asPage(): this is PageCard {
    return this.cardKbn === Kbns.CARD_KBN.PAGE;
  }

  asTweet(): this is TweetCard {
    return this.cardKbn === Kbns.CARD_KBN.TWEET;
  }
}

export class PageCard extends Card<PageCard> {
  readonly cardKbn: Kbn<'CARD_KBN'> = Kbns.CARD_KBN.PAGE;

  layoutKbn: Kbn<'PAGE_CARD_LAYOUT_KBN'> = Kbns.PAGE_CARD_LAYOUT_KBN.IMAGE_BUTTON_SINGLE;

  imageMediaAry: Media[] = [];

  videoMedia: Media | null = null;

  mediaImageRowCount = 0;

  mediaImageColumnCount = 0;

  textContent = '';

  startFlag = false;

  buttons: Button[] = [];

  get usesImageMedia() {
    return this.layoutKbn !== Kbns.PAGE_CARD_LAYOUT_KBN.VIDEO_BUTTON_SINGLE;
  }

  get usesVideoMedia() {
    return this.layoutKbn === Kbns.PAGE_CARD_LAYOUT_KBN.VIDEO_BUTTON_SINGLE;
  }

  get usedMedia() {
    if (this.usesImageMedia) {
      return this.imageMediaAry;
    } else if (this.usesVideoMedia) {
      return _.compact([this.videoMedia]);
    } else {
      return [];
    }
  }

  set usedMedia(media: Media[]) {
    if (this.usesImageMedia) {
      this.imageMediaAry = media;
    } else if (this.usesVideoMedia) {
      this.videoMedia = media[0];
    }
  }

  get usedButtons() {
    return this.buttons.filter(button => !button.isEmpty);
  }
}

registerGenerator(
  PageCard,
  ({ size }) => new PageCard().mutate({
    uuid: uuidv4(),
    campaignUuid: uuidv4(),
    cardNm: _.repeat('僕のカード名', size),
    textContent: _.repeat('僕のページ内容。\n', size),
    mediaImageColumnCount: 1,
    mediaImageRowCount: 1,
  }),
);

export class TweetCard extends Card<TweetCard> {
  readonly cardKbn: Kbn<'CARD_KBN'> = Kbns.CARD_KBN.TWEET;

  tweetContent = '';

  tweetContentLinkKbn: Kbn<'TWEET_CONTENT_LINK_KBN'> = Kbns.TWEET_CONTENT_LINK_KBN.GENERATED;

  twitterCardLayoutKbn: Kbn<'TWITTER_CARD_LAYOUT_KBN'> = Kbns.TWITTER_CARD_LAYOUT_KBN.SUMMARY_LARGE_IMAGE;

  twitterCardTitleContent = '';

  twitterCardMedia: Media | null = null;

  twitterCardDescriptionContent = '';

  twitterCardImageAltContent = '';

  twitterCardHtmlFileCode = '';

  get isLinkGenerated() {
    return this.tweetContentLinkKbn === Kbns.TWEET_CONTENT_LINK_KBN.GENERATED;
  }

  get isLinkCustom() {
    return this.tweetContentLinkKbn === Kbns.TWEET_CONTENT_LINK_KBN.CUSTOM;
  }
}

registerGenerator(
  TweetCard,
  ({ size }) => new TweetCard().mutate({
    uuid: uuidv4(),
    campaignUuid: uuidv4(),
    cardNm: _.repeat('僕のカード名', size),
    tweetContent: _.repeat('僕のツイート内容。\n', size),
    tweetContentLinkKbn: Kbns.TWEET_CONTENT_LINK_KBN.GENERATED,
    twitterCardTitleContent: _.repeat('僕のカードタイトル', size),
    twitterCardDescriptionContent: _.repeat('僕のカード説明', size),
  }),
);
