
import { defineComponent, PropType } from 'vue';

import { TweetCard } from '@/api/models/card';
import CardBoardFrame from '@/components/CardBoardFrame.vue';
import HighlightArea from '@/components/HighlightArea.vue';
import MediaPreview from '@/components/MediaPreview.vue';

/**
 * `TweetCard`の`Board`上の表示。
 */
export default defineComponent({
  name: 'TweetCardBoardView',
  components: {
    CardBoardFrame,
    MediaPreview,
    HighlightArea,
  },
  props: {

    /**
     * 表示するカード
     */
    card: {
      type: Object as PropType<TweetCard>,
      required: true,
    },

    /**
     * このカードが何かと接続しているかどうか
     */
    connected: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'click',
  ],
});
