<template>
  <MessageBar
    v-if="errors.length > 0"
    variant="error"
    @close="clearErrors"
  >
    <div
      v-for="(error, index) in errors"
      :key="index"
    >
      {{ error }}
    </div>
  </MessageBar>
</template>

<script lang="ts">
import { defineComponent } from 'vue-demi';

import MessageBar from '@/components/MessageBar.vue';
import { useCurrentErrors } from '@/hooks/use_errors';

/**
 * `useErrors`の内容を表示するメッセージバー。
 */
export default defineComponent({
  name: 'CurrentErrorsMessageBar',
  components: {
    MessageBar,
  },
  setup() {
    const { errors, clearErrors } = useCurrentErrors();

    return {
      errors,
      clearErrors,
    };
  },
});
</script>
