
import { UvIcon } from '@uniquevision/beluga-ui';
import { defineComponent, PropType } from 'vue-demi';

export default defineComponent({
  name: 'MessageBar',
  components: {
    UvIcon,
  },
  props: {
    variant: {
      type: String as PropType<'success' | 'error'>,
      required: true,
    },
  },
  emits: [
    'close',
  ],
});
