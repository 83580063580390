import { Type } from 'class-transformer';
import { addDays, subDays } from 'date-fns';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { Scenario } from '@/api/models/scenario';
import TransformTimestampToDate from '@/decorators/transform_timestamp_to_date';
import { registerGenerator } from '@/utilities/generate';
import { Model } from '@/utilities/model';

export class Campaign extends Model<Campaign> {
  uuid = uuidv4();

  campaignNm = '';

  companyNm = '';

  twitterAccountNm = '';

  twitterFollowerCheckFlag = false;

  twitterLotteryWinnerCount = 0;

  @TransformTimestampToDate
  twitterLotteryWinnerDmAt = new Date(0);

  customDomainContent = '';

  pageTitleContent = '';

  @TransformTimestampToDate
  startAt = new Date(0);

  @TransformTimestampToDate
  endAt = new Date(0);

  @TransformTimestampToDate
  publicOpenAt = new Date(0);

  @TransformTimestampToDate
  siteCloseAt = new Date(0);

  @TransformTimestampToDate
  deadlineAt = new Date(0);

  promotionsContent = '';

  remarksContent = '';

  editAreaHeight = 0;

  editAreaWidth = 0;

  videoAutoplayFlag = false;

  videoControlsFlag = false;

  @Type(() => Scenario)
  cards: Scenario = new Scenario();

  @TransformTimestampToDate
  createdAt = new Date(0);

  @TransformTimestampToDate
  updatedAt = new Date(0);
}

registerGenerator(
  Campaign,
  ({ now, size }) => new Campaign().mutate({
    campaignNm: _.repeat('僕のキャンペーン', size),
    companyNm: _.repeat('僕の企業', size),
    twitterAccountNm: _.repeat('僕のTwitterアカウント', size),
    twitterFollowerCheckFlag: false,
    twitterLotteryWinnerCount: 100 * size,
    twitterLotteryWinnerDmAt: now,
    customDomainContent: `custom.${_.repeat('domain.', size)}com`,
    pageTitleContent: _.repeat('ページタイトル', size),
    startAt: subDays(now, 1),
    endAt: addDays(now, 1),
    publicOpenAt: subDays(now, 2),
    siteCloseAt: addDays(now, 2),
    deadlineAt: subDays(now, 7),
    promotionsContent: _.repeat('僕のプロモーション\n', size),
    remarksContent: _.repeat('僕の備考\n', size),
    editAreaHeight: size,
    editAreaWidth: size,
    videoAutoplayFlag: false,
    videoControlsFlag: false,
    createdAt: now,
    updatedAt: now,
  }),
);
