/* eslint-disable @typescript-eslint/ban-types */
import _ from 'lodash';

export type RecursivePartial<T> = {
  [P in keyof T]?:
  T[P] extends (infer U)[] ? RecursivePartial<U>[] :
    T[P] extends object ? RecursivePartial<T[P]> :
      T[P];
};

/**
 * データ系クラスを扱いやすくするメタクラス。
 */
export abstract class Model<T> {
  /**
   * パッチをこのデータに再帰的に当てはめる。
   *
   * ```ts
   * this == this.mutate()
   * ```
   *
   * @param patch 新しいデータ。`undefined`はコピーされない。
   * @returns このデータ、変化済み。
   */
  mutate(patch?: RecursivePartial<T>): T {
    return _.merge(this as unknown as T, patch);
  }

  /**
   * 新しいデータを生成し、パッチを再帰的に当てはめる。
   *
   * ```ts
   * this != this.clone()
   * ```
   *
   * @param patch 新しいデータ。`undefined`はコピーされない。
   * @returns 新しいデータ、変化済み。
   */
  clone(patch?: RecursivePartial<T>) {
    const prototype = Object.getPrototypeOf(this);
    const instance: T = new prototype.constructor();

    return _.merge(instance, this, patch);
  }
}
