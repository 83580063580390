<template>
  <template v-if="kbn === $kbn.TWITTER_CARD_LAYOUT_KBN.SUMMARY_LARGE_IMAGE">
    <div>
      <div
        :style="{
          'aspect-ratio': '2',
        }"
      >
        <MediaPreview
          class="rounded-tl-lg rounded-tr-lg"
          type="image"
          :media="media"
        />
      </div>
      <div class="border border-solid border-gray-light rounded-bl-lg rounded-br-lg space-y-2 p-4">
        <div class="text-sm font-bold whitespace-pre-line break-all line-clamp-2">
          {{ title }}
        </div>
        <div class="text-sm whitespace-pre-line break-all line-clamp-2">
          {{ description }}
        </div>
      </div>
    </div>
  </template>
  <template v-else-if="kbn === $kbn.TWITTER_CARD_LAYOUT_KBN.SUMMARY">
    <div class="flex">
      <div
        class="w-1/3"
        :style="{
          'aspect-ratio': '1',
        }"
      >
        <MediaPreview
          class="rounded-tl-lg rounded-bl-lg"
          type="image"
          :media="media"
        />
      </div>
      <div class="w-2/3 flex-shrink-0 border border-solid border-gray-light rounded-tr-lg rounded-br-lg space-y-2 p-4">
        <div class="text-sm font-bold whitespace-pre-line break-all line-clamp-2">
          {{ title }}
        </div>
        <div class="text-sm whitespace-pre-line break-all line-clamp-2">
          {{ description }}
        </div>
      </div>
    </div>
  </template>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue-demi';

import { Media } from '@/api/models/media';
import MediaPreview from '@/components/MediaPreview.vue';
import { Kbn } from '@/utilities/kbn';

export default defineComponent({
  name: 'TwitterCardPreview',
  components: {
    MediaPreview,
  },
  props: {

    /**
     * ツイッターカードのレイアウト
     */
    kbn: {
      type: String as PropType<Kbn<'TWITTER_CARD_LAYOUT_KBN'>>,
      required: true,
    },

    /**
     * ツイッターカードのタイトル
     */
    title: {
      type: String,
      required: true,
    },

    /**
     * ツイッターカードの詳細
     */
    description: {
      type: String,
      required: true,
    },

    /**
     * カード内で表示する画像
     */
    media: {
      type: Object as PropType<Media | null>,
      default: null,
    },
  },
});
</script>
