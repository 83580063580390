
import { defineComponent } from 'vue-demi';

export default defineComponent({
  name: 'CardFormFieldRow',
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
});
