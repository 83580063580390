<template>
  <div class="flex items-center">
    <uv-button
      class="font-bold mr-5 my-1"
      :disabled="state === 'busy'"
      @click="onPickFile"
    >
      アップロード
    </uv-button>
    <template v-if="state === 'view'">
      <template v-if="media">
        <LinkText
          class="break-all line-clamp-1 mr-2"
          @click="onDownload"
        >
          {{ media.fileNm }}
        </LinkText>
        <uv-icon-button
          class="text-danger"
          icon="trash"
          @click="onDelete"
        />
      </template>
    </template>
    <template v-else-if="state === 'busy'">
      <uv-loading
        :size="24"
      />
    </template>
    <input
      ref="input"
      type="file"
      class="hidden"
      @change="onPickedFile"
    />
  </div>
</template>

<script lang="ts">
import { UvButton, UvIconButton, UvLoading } from '@uniquevision/beluga-ui';
import { templateRef } from '@vueuse/core';
import { defineComponent, PropType, ref } from 'vue-demi';

import { Media } from '@/api/models/media';
import LinkText from '@/components/LinkText.vue';
import { useApi } from '@/hooks/use_api';

type State =
  'view' |
  'busy';

export default defineComponent({
  name: 'InlineMediaUploader',
  components: {
    UvButton,
    UvIconButton,
    UvLoading,
    LinkText,
  },
  props: {

    /**
     * v-model用
     */
    media: {
      type: Object as PropType<Media | null>,
      default: null,
    },
  },
  emits: [
    'update:media',
  ],
  setup(props, { emit }) {
    const api = useApi();
    const inputEl = templateRef<HTMLInputElement>('input');
    const state = ref<State>('view');

    function onDownload() {
      props.media?.download();
    }

    function onDelete() {
      emit('update:media', null);
    }

    function onPickFile() {
      inputEl.value?.click();
    }

    async function onPickedFile() {
      const currentInputEl = inputEl.value;

      if (!currentInputEl) {
        return;
      }

      const files = currentInputEl.files ?? [];

      if (files.length === 0) {
        return;
      }

      const file = files[0];
      currentInputEl.value = '';

      try {
        state.value = 'busy';
        const media = await api.postMedia(file);

        if (media) {
          emit('update:media', media);
        }
      } finally {
        state.value = 'view';
      }
    }

    return {
      onPickFile,
      onPickedFile,
      onDownload,
      onDelete,
      state,
    };
  },
});
</script>
