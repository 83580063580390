
import { defineComponent, PropType } from 'vue-demi';

import { PageCard } from '@/api/models/card';
import CardButtonPreview from '@/components/CardButtonPreview.vue';
import CardPreviewFrame from '@/components/CardPreviewFrame.vue';
import NewlinedText from '@/components/NewlinedText.vue';
import PageCardMediaPreview from '@/components/PageCardMediaPreview.vue';

export default defineComponent({
  name: 'PageCardPreview',
  components: {
    NewlinedText,
    PageCardMediaPreview,
    CardButtonPreview,
    CardPreviewFrame,
  },
  props: {

    /**
     * プレビューされるカード
     */
    card: {
      type: Object as PropType<PageCard>,
      required: true,
    },
  },
});
