<template>
  <div
    :id="`board-connection-point-${connectionId}`"
    class="board-connection-point w-0 h-0"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BoardConnectionPoint',
  props: {

    /**
     * この接続点のID
     */
    connectionId: {
      type: String,
      required: true,
    },
  },
});
</script>
