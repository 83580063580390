
import { UvTextInput } from '@uniquevision/beluga-ui';
import { useVModel } from '@vueuse/core';
import { defineComponent, PropType } from 'vue-demi';

import { Button } from '@/api/models/button';
import CardFormFieldRow from '@/components/CardFormFieldRow.vue';

import ButtonTypeSelect from './ButtonTypeSelect.vue';

export default defineComponent({
  name: 'ButtonCardFormFieldRow',
  components: {
    ButtonTypeSelect,
    CardFormFieldRow,
    UvTextInput,
  },
  props: {

    /**
     * v-model用
     */
    button: {
      type: Object as PropType<Button>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const model = useVModel(props, 'button', emit, {
      deep: true,
    });

    return {
      model,
    };
  },
});
