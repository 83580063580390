<template>
  <uv-select v-model="kbn">
    <template #options>
      <uv-select-option :value="$kbn.TWEET_CONTENT_LINK_KBN.GENERATED">
        システムで作成する
      </uv-select-option>
      <uv-select-option :value="$kbn.TWEET_CONTENT_LINK_KBN.CUSTOM">
        システムで作成しない
      </uv-select-option>
    </template>
  </uv-select>
</template>

<script lang="ts">
import { UvSelect, UvSelectOption } from '@uniquevision/beluga-ui';
import { useVModel } from '@vueuse/core';
import { defineComponent, PropType } from 'vue-demi';

import { Kbn } from '@/utilities/kbn';

export default defineComponent({
  name: 'TweetContentLinkSelect',
  components: {
    UvSelect,
    UvSelectOption,
  },
  props: {

    /**
     * v-model用
     */
    modelValue: {
      type: String as PropType<Kbn<'TWEET_CONTENT_LINK_KBN'>>,
      required: true,
    },
  },
  emits: [

    /**
     * v-model用
     */
    'update:modelValue',
  ],
  setup(props, { emit }) {
    const kbn = useVModel(props, 'modelValue', emit);

    return {
      kbn,
    };
  },
});
</script>
