
import { UvButton, UvDialog, UvIcon } from '@uniquevision/beluga-ui';
import _ from 'lodash';
import { defineComponent, PropType, ref } from 'vue-demi';

import { Card } from '@/api/models/card';
import CurrentErrorsMessageBar from '@/components/CurrentErrorsMessageBar.vue';

import DeleteConfirmationDialog from './DeleteConfirmationDialog.vue';

/**
 * それぞれのカード編集ダイヤログの共通スタイル。
 */
export default defineComponent({
  name: 'CardFormDialog',
  components: {
    UvButton,
    UvIcon,
    UvDialog,
    CurrentErrorsMessageBar,
    DeleteConfirmationDialog,
  },
  props: {

    /**
     * 編集されるカード
     */
    card: {
      type: Object as PropType<Card>,
      default: null,
    },

    /**
     * 保存する時
     */
    onSubmit: {
      type: Function,
      default: () => _.noop,
    },

    /**
     * 削除する時
     */
    onDelete: {
      type: Function,
      default: () => _.noop,
    },

    /**
     * キャンセルする時
     */
    onCancel: {
      type: Function,
      default: () => _.noop,
    },
  },
  setup(props) {
    const confirmDeletionDialog = ref(false);

    async function onConfirmDeletion() {
      props.onDelete();
      confirmDeletionDialog.value = false;
    }

    async function onCancelDeletion() {
      confirmDeletionDialog.value = false;
    }

    return {
      confirmDeletionDialog,
      onConfirmDeletion,
      onCancelDeletion,
    };
  },
});
