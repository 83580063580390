import _ from 'lodash';
import * as zod from 'zod';

const NO_ERROR = Object.freeze({
  form: [],
  // eslint-disable-next-line object-curly-newline
  fields: {},
});

/**
 * 簡単なフォーム機能を提供する。
 */
export abstract class Form<Fields> {
  /**
   * 入力可能な要素
   */
  abstract fields: Fields;

  /**
   * バリデーション条件
   */
  abstract schema: zod.Schema<Partial<Fields>>;

  /**
   * 現在のバリデーションエラー
   *
   * TODO: これは`protected`なはずだが、Vue3のバグでできない。
   *
   * https://github.com/vuejs/vue-next/issues/2557
   * https://github.com/vuejs/vue-next/issues/2981
   * https://github.com/vuejs/vue-next/issues/3815
   */
  latestError: {
    form: string[]
    fields: Partial<Record<keyof Fields, string>>
  } = NO_ERROR;

  get error() {
    return this.latestError;
  }

  /**
   * バリデーションを実行しフィールドごとのエラーを返す。
   *
   * @returns 成功かどうか。情報自体は {error} を読んでください。
   */
  validate() {
    try {
      this.schema.parse(this.fields, {
        async: false,
      });

      this.latestError = NO_ERROR;

      return true;
    } catch (error) {
      if (error instanceof zod.ZodError) {
        this.latestError = {
          form: error.formErrors.formErrors,
          fields: _.mapValues(error.formErrors.fieldErrors, _.first) as Record<keyof Fields, string>,
        };

        return false;
      } else {
        // バリデーションと関係エラー。（基本的に発生しない。）
        throw error;
      }
    }
  }
}
