<template>
  <div class="flex flex-col items-center justify-center cursor-pointer shadow-small rounded-default h-12 w-12 bg-white">
    <uv-icon
      class="text-primary"
      :icon="icon"
      :size="18"
      :style="{
        marginTop: '4px',
        marginBottom: '2px',
      }"
    />
    <div class="text-2xs font-bold text-gray-default">
      {{ name }}
    </div>
  </div>
</template>

<script lang="ts">
import { UvIcon } from '@uniquevision/beluga-ui';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AddBoardCardButton',
  components: {
    UvIcon,
  },
  props: {

    /**
     * ボードカード名
     *
     * ※ 長い名前に対応していない。３文字まで大丈夫。
     */
    name: {
      type: String,
      required: true,
    },

    /**
     * ボードカードアイコン
     */
    icon: {
      type: String,
      required: true,
    },
  },
});
</script>
