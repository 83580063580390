
import { UvDialog } from '@uniquevision/beluga-ui';
import { useVModel } from '@vueuse/core';
import _ from 'lodash';
import { computed, defineComponent, PropType, ref } from 'vue-demi';

import { Card } from '@/api/models/card';
import { Scenario } from '@/api/models/scenario';
import PageCardBoardView from '@/components/PageCardBoardView.vue';
import PageCardFormDialog from '@/components/PageCardFormDialog.vue';
import TweetCardBoardView from '@/components/TweetCardBoardView.vue';
import TweetCardFormDialog from '@/components/TweetCardFormDialog.vue';

export default defineComponent({
  name: 'CardBoardView',
  components: {
    UvDialog,
    PageCardBoardView,
    PageCardFormDialog,
    TweetCardBoardView,
    TweetCardFormDialog,
  },
  props: {
    modelValue: {
      type: Object as PropType<Scenario>,
      required: true,
    },
    card: {
      type: Object as PropType<Card>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const dialog = ref(false);
    const isConnected = computed(() => {
      return _.some(
        props.modelValue.getConnected(),
        card => card.uuid === props.card.uuid,
      );
    });

    const scenario = useVModel(props, 'modelValue', emit);

    async function onSubmit(card: Card) {
      const index = scenario.value.findIndex(candidate => candidate.uuid === card.uuid);

      if (index !== -1) {
        scenario.value[index] = card;
      }

      dialog.value = false;
    }

    async function onDelete(card: Card) {
      const index = scenario.value.findIndex(candidate => candidate.uuid === card.uuid);

      if (index !== -1) {
        scenario.value.splice(index, 1);
      }

      dialog.value = false;
    }

    async function onCancel() {
      dialog.value = false;
    }

    return {
      dialog,
      isConnected,
      onSubmit,
      onDelete,
      onCancel,
    };
  },
});
