
import { defineComponent, PropType } from 'vue-demi';

import { TweetCard } from '@/api/models/card';
import CardPreviewFrame from '@/components/CardPreviewFrame.vue';
import TwitterCardPreview from '@/components/TwitterCardPreview.vue';

export default defineComponent({
  name: 'TweetCardPreview',
  components: {
    CardPreviewFrame,
    TwitterCardPreview,
  },
  props: {

    /**
     * プレビューで表示するカード
     */
    card: {
      type: Object as PropType<TweetCard>,
      required: true,
    },
  },
});
