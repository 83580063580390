import _ from 'lodash';
import { inject, InjectionKey, provide, readonly, Ref, ref } from 'vue-demi';

import { Button } from '@/api/models/button';
import { Card } from '@/api/models/card';
import { ScenarioConnection } from '@/api/models/scenario';

export interface BoardConnectionContext {
  readonly isConnecting: Ref<boolean>
  readonly partialConnection: Ref<{
    readonly source: Card
    readonly button: Button
  } | null>

  onStart(source: Card, button: Button): void
  onEnd(target: Card): void
  onCancel(): void
}

const BOARD_CONNECTION_DND_KEY: InjectionKey<BoardConnectionContext> = Symbol('board-connection-dnd-key');

export type BoardConnectionHandler = (connection: ScenarioConnection) => void;

export function useBoardConnection(onConnection: BoardConnectionHandler) {
  const isConnecting = ref<boolean>(false);
  const partialConnection = ref<{
    source: Card
    button: Button
  } | null>(null);

  const context: BoardConnectionContext = {
    isConnecting,
    partialConnection,
    onStart(source, button) {
      partialConnection.value = {
        source,
        button,
      };

      isConnecting.value = true;
    },
    onEnd(target) {
      if (!partialConnection.value) {
        return;
      }

      const { source, button } = partialConnection.value;

      if (source.uuid === target.uuid) {
        return;
      }

      onConnection(new ScenarioConnection(source, target, button));
    },
    onCancel() {
      isConnecting.value = false;
    },
  };

  provide(BOARD_CONNECTION_DND_KEY, context);

  return context;
}

export function useCurrentBoardConnection(): BoardConnectionContext {
  return inject(BOARD_CONNECTION_DND_KEY, {
    isConnecting: readonly(ref(false)),
    partialConnection: readonly(ref(null)),
    onStart: _.noop,
    onEnd: _.noop,
    onCancel: _.noop,
  });
}
