
import { UvTextArea, UvTextInput } from '@uniquevision/beluga-ui';
import _ from 'lodash';
import { defineComponent, PropType, ref } from 'vue-demi';

import { TweetCardForm } from '@/api/forms/tweet_card_form';
import { TweetCard } from '@/api/models/card';
import CardFormDialog from '@/components/CardFormDialog.vue';
import CardFormFieldBox from '@/components/CardFormFieldBox.vue';
import CardFormFieldDelimiter from '@/components/CardFormFieldDelimiter.vue';
import CardFormFieldRow from '@/components/CardFormFieldRow.vue';
import InlineMediaUploader from '@/components/InlineMediaUploader.vue';
import LinkText from '@/components/LinkText.vue';
import TweetCardPreview from '@/components/TweetCardPreview.vue';
import TweetContentLinkSelect from '@/components/TweetContentLinkSelect.vue';
import TwitterCardLayoutSelect from '@/components/TwitterCardLayoutSelect.vue';
import { useErrors } from '@/hooks/use_errors';

export default defineComponent({
  name: 'TweetCardFormDialog',
  components: {
    UvTextArea,
    UvTextInput,
    CardFormDialog,
    CardFormFieldBox,
    CardFormFieldDelimiter,
    CardFormFieldRow,
    InlineMediaUploader,
    TweetCardPreview,
    TweetContentLinkSelect,
    TwitterCardLayoutSelect,
    LinkText,
  },
  props: {

    /**
     * 編集するカード
     */
    card: {
      type: Object as PropType<TweetCard>,
      required: false,
      default: undefined,
    },

    /**
     * 登録する時
     */
    onSubmit: {
      type: Function as PropType<(form: TweetCardForm) => Promise<void>>,
      default: () => _.noop,
    },

    /**
     * 削除する時
     */
    onDelete: {
      type: Function as PropType<() => Promise<void>>,
      default: () => _.noop,
    },

    /**
     * キャンセルする時
     */
    onCancel: {
      type: Function as PropType<() => Promise<void>>,
      default: () => _.noop,
    },
  },
  setup(props) {
    const { clearErrors, reportFormErrors } = useErrors();
    const form = ref(new TweetCardForm(props.card));

    function onValidateAndSubmit(form: TweetCardForm) {
      clearErrors();
      const success = form.validate();

      if (success) {
        props.onSubmit(form);
      } else {
        reportFormErrors(form.latestError);
      }
    }

    return {
      form,
      onValidateAndSubmit,
    };
  },
});
