
import { computed, defineComponent, PropType } from 'vue-demi';

import { Kbn } from '@/utilities/kbn';

/**
 * `PageCard`のメディアを表示するコンポーネント。
 */
export default defineComponent({
  name: 'PageCardMediaGrid',
  props: {
    kbn: {
      type: String as PropType<Kbn<'PAGE_CARD_LAYOUT_KBN'>>,
      required: true,
    },

    columns: {
      type: Number,
      required: true,
    },

    rows: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const aspectRatio = computed(() => {
      // １枚しかない場合はメディアの比率をグリッドに合わせない。
      if (props.columns === 1 && props.rows === 1) {
        return undefined;
      }

      // メディアを縦に並べる時、デザインが違う。
      if (props.columns === 1 && props.rows > 1) {
        return `${2.0}`;
      }

      // 普段はどれも四角。
      return `${1.0}`;
    });

    return {
      aspectRatio,
    };
  },
});
