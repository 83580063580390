
import { UvIcon } from '@uniquevision/beluga-ui';
import { defineComponent, PropType, ref, watch } from 'vue-demi';

import { Media } from '@/api/models/media';

export default defineComponent({
  name: 'MediaPreview',
  components: {
    UvIcon,
  },
  inheritAttrs: false,
  props: {

    /**
     * メディアの種類
     */
    type: {
      type: String as PropType<'image' | 'video'>,
      required: true,
    },

    /**
     * 表示するメディア
     */
    media: {
      type: Object as PropType<Media | null>,
      default: null,
    },
  },
  setup(props) {
    const error = ref(false);

    watch(() => props.media, () => {
      error.value = false;
    }, {
      deep: true,
    });

    return {
      error,
    };
  },
});
