<template>
  <uv-select v-model="kbn">
    <template #options>
      <uv-select-option :value="$kbn.TWITTER_CARD_LAYOUT_KBN.SUMMARY_LARGE_IMAGE">
        summary_large_image
      </uv-select-option>
      <uv-select-option :value="$kbn.TWITTER_CARD_LAYOUT_KBN.SUMMARY">
        summary
      </uv-select-option>
    </template>
  </uv-select>
</template>

<script lang="ts">
import { UvSelect, UvSelectOption } from '@uniquevision/beluga-ui';
import { useVModel } from '@vueuse/core';
import { defineComponent, PropType } from 'vue-demi';

import { Kbn } from '@/utilities/kbn';

export default defineComponent({
  name: 'TwitterCardLayoutSelect',
  components: {
    UvSelect,
    UvSelectOption,
  },
  props: {

    /**
     * v-model用
     */
    modelValue: {
      type: String as PropType<Kbn<'TWITTER_CARD_LAYOUT_KBN'>>,
      required: true,
    },
  },
  emits: [

    /**
     * v-model用
     */
    'update:modelValue',
  ],
  setup(props, { emit }) {
    const kbn = useVModel(props, 'modelValue', emit);

    return {
      kbn,
    };
  },
});
</script>
