<template>
  <PageCardMediaGrid
    :kbn="form.fields.layoutKbn"
    :columns="form.fields.mediaImageColumnCount"
    :rows="form.fields.mediaImageRowCount"
  >
    <template #image="{ index }">
      <MediaUploader
        v-model:media="form.fields.imageMediaAry[index]"
        class="rounded-lg"
        type="image"
      />
    </template>
    <template #video>
      <MediaUploader
        v-model:media="form.fields.videoMedia"
        class="rounded-lg"
        type="video"
      />
    </template>
  </PageCardMediaGrid>
</template>

<script lang="ts">
import { useVModel } from '@vueuse/core';
import { defineComponent, PropType } from 'vue-demi';

import { PageCardForm } from '@/api/forms/page_card_form';
import MediaUploader from '@/components/MediaUploader.vue';
import PageCardMediaGrid from '@/components/PageCardMediaGrid.vue';

export default defineComponent({
  name: 'PageCardMediaUploader',
  components: {
    MediaUploader,
    PageCardMediaGrid,
  },
  props: {
    modelValue: {
      type: Object as PropType<PageCardForm>,
      required: true,
    },
  },
  emits: [
    'update:modelValue',
  ],
  setup(props, { emit }) {
    const form = useVModel(props, 'modelValue', emit);

    return {
      form,
    };
  },
});
</script>
