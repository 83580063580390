<template>
  <PageCardMediaGrid
    :kbn="card.layoutKbn"
    :columns="card.mediaImageColumnCount"
    :rows="card.mediaImageRowCount"
  >
    <template #image="{ index }">
      <MediaPreview
        type="image"
        class="rounded-lg overflow-hidden"
        :media="card.imageMediaAry[index]"
      />
    </template>
    <template #video>
      <MediaPreview
        type="video"
        :media="card.videoMedia"
      />
    </template>
  </PageCardMediaGrid>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue-demi';

import { PageCard } from '@/api/models/card';
import MediaPreview from '@/components/MediaPreview.vue';
import PageCardMediaGrid from '@/components/PageCardMediaGrid.vue';

export default defineComponent({
  name: 'PageCardMediaPreview',
  components: {
    MediaPreview,
    PageCardMediaGrid,
  },
  props: {
    card: {
      type: Object as PropType<PageCard>,
      required: true,
    },
  },
});
</script>
