
import { defineComponent, onMounted, ref } from 'vue-demi';

import HighlightArea from '@/components/HighlightArea.vue';
import SmoothCurve from '@/components/SmoothCurve.vue';
import { useElementPositionInElement } from '@/hooks/use_position_in_element';

export default defineComponent({
  name: 'BoardCurve',
  components: {
    SmoothCurve,
    HighlightArea,
  },
  inheritAttrs: false,
  props: {

    /**
     * ボード要素のID
     */
    boardId: {
      type: String,
      required: true,
    },

    /**
     * 接続開始点のID
     */
    connectionStartId: {
      type: String,
      required: true,
    },

    /**
     * 接続終了点のID
     */
    connectionEndId: {
      type: String,
      required: true,
    },

    /**
     * `useHighlight*`用のID
     *
     * ハイライトする時にスタイルが変わる。
     */
    highlightId: {
      type: String,
      required: true,
    },

    /**
     * 曲線のCSSクラス
     */
    curveClass: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const board = ref<Element | null>(null);
    const start = useElementPositionInElement(props.connectionStartId, board);
    const end = useElementPositionInElement(props.connectionEndId, board);

    onMounted(() => {
      board.value = document.getElementById(props.boardId);
    });

    return {
      start,
      end,
    };
  },
});
