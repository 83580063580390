
import { UvSelect, UvSelectOption } from '@uniquevision/beluga-ui';
import { useVModel } from '@vueuse/core';
import { computed, defineComponent, PropType } from 'vue-demi';

import { PageCardForm } from '@/api/forms/page_card_form';
import { Kbn } from '@/utilities/kbn';

interface Layout {
  kbn: Kbn<'PAGE_CARD_LAYOUT_KBN'>
  rows: number
  columns: number
}

export default defineComponent({
  name: 'PageCardLayoutSelect',
  components: {
    UvSelect,
    UvSelectOption,
  },
  props: {
    modelValue: {
      type: Object as PropType<PageCardForm>,
      required: true,
    },
  },
  emits: [
    'update:modelValue',
  ],
  setup(props, { emit }) {
    const form = useVModel(props, 'modelValue', emit);

    const layout = computed({
      get(): Layout {
        return {
          kbn: form.value.fields.layoutKbn,
          columns: form.value.fields.mediaImageColumnCount,
          rows: form.value.fields.mediaImageRowCount,
        };
      },
      set(layout: Layout) {
        form.value.fields.layoutKbn = layout.kbn;
        form.value.fields.mediaImageColumnCount = layout.columns;
        form.value.fields.mediaImageRowCount = layout.rows;
      },
    });

    return {
      layout,
    };
  },
});
