<template>
  <CardFormDialog
    :card="card"
    @submit="onValidateAndSubmit(form)"
    @delete="onDelete"
    @cancel="onCancel"
  >
    <div class="flex space-x-4">
      <div class="space-y-4">
        <PageCardPreview :card="form.card">
          <template #media>
            <PageCardMediaUploader v-model="form" />
          </template>
        </PageCardPreview>
        <div class="text-sm">
          ※プレビューはイメージです。調整は入稿完了も可能です。
        </div>
      </div>
      <div class="flex-grow">
        <CardFormFieldBox>
          <CardFormFieldRow title="カード名">
            <uv-text-input
              v-model="form.fields.cardNm"
              :maxlength="32"
            />
          </CardFormFieldRow>
          <CardFormFieldRow title="メディア">
            <div class="flex flex-col space-y-2">
              <PageCardLayoutSelect v-model="form" />
              <LinkText class="self-end">
                メディアについてはこちら
              </LinkText>
            </div>
          </CardFormFieldRow>
          <CardFormFieldDelimiter />
          <CardFormFieldRow title="テキスト" subtitle="(100文字)">
            <RichTextInput v-model="form.fields.textContent" />
          </CardFormFieldRow>
          <ButtonCardFormFieldRow
            v-for="(button, index) of form.fields.buttons"
            :key="button.uuid"
            v-model:button="form.fields.buttons[index]"
          />
        </CardFormFieldBox>
      </div>
    </div>
  </CardFormDialog>
</template>

<script lang="ts">
import { UvTextInput } from '@uniquevision/beluga-ui';
import _ from 'lodash';
import { defineComponent, PropType, ref } from 'vue-demi';

import { PageCardForm } from '@/api/forms/page_card_form';
import { PageCard } from '@/api/models/card';
import { useErrors } from '@/hooks/use_errors';

import ButtonCardFormFieldRow from './ButtonCardFormFieldRow.vue';
import CardFormDialog from './CardFormDialog.vue';
import CardFormFieldBox from './CardFormFieldBox.vue';
import CardFormFieldDelimiter from './CardFormFieldDelimiter.vue';
import CardFormFieldRow from './CardFormFieldRow.vue';
import LinkText from './LinkText.vue';
import PageCardLayoutSelect from './PageCardLayoutSelect.vue';
import PageCardMediaUploader from './PageCardMediaUploader.vue';
import PageCardPreview from './PageCardPreview.vue';
import RichTextInput from './RichTextInput.vue';

export default defineComponent({
  name: 'PageCardFormDialog',
  components: {
    UvTextInput,
    CardFormDialog,
    CardFormFieldBox,
    CardFormFieldDelimiter,
    CardFormFieldRow,
    ButtonCardFormFieldRow,
    PageCardPreview,
    PageCardLayoutSelect,
    PageCardMediaUploader,
    RichTextInput,
    LinkText,
  },
  props: {

    /**
     * 編集するカード
     */
    card: {
      type: Object as PropType<PageCard>,
      default: undefined,
    },

    /**
     * 登録する時
     */
    onSubmit: {
      type: Function as PropType<(form: PageCardForm) => Promise<void>>,
      default: () => _.noop,
    },

    /**
     * 削除する時
     */
    onDelete: {
      type: Function as PropType<() => Promise<void>>,
      default: () => _.noop,
    },

    /**
     * キャンセルする時
     */
    onCancel: {
      type: Function as PropType<() => Promise<void>>,
      default: () => _.noop,
    },
  },
  setup(props) {
    const { clearErrors, reportFormErrors } = useErrors();
    const form = ref(new PageCardForm(props.card));

    function onValidateAndSubmit(form: PageCardForm) {
      const success = form.validate();
      clearErrors();

      if (success) {
        props.onSubmit(form);
      } else {
        reportFormErrors(form.latestError);
      }
    }

    return {
      form,
      onValidateAndSubmit,
    };
  },
});
</script>
