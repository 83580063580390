import '@/styles/index.scss';

import { useUvIconMap } from '@uniquevision/beluga-ui';
import { App } from 'vue';
import VueGridLayout from 'vue-grid-layout';

import { installKbns } from './utilities/kbn';

function addUvIcon(name: string) {
  const icons = useUvIconMap();
  icons[name] = require(`!!html-loader!./assets/${name}.svg`);
}

export default function setup(app: App) {
  installKbns(app);

  addUvIcon('add');
  addUvIcon('cancel');
  addUvIcon('close');
  addUvIcon('download');
  addUvIcon('link');
  addUvIcon('list');
  addUvIcon('plus');
  addUvIcon('return');
  addUvIcon('share');
  addUvIcon('trash');

  app.use(VueGridLayout);
}
