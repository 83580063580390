<template>
  <div class="relative h-full">
    <template v-if="state === 'view'">
      <template v-if="media">
        <div class="absolute z-10 top-0 right-0 rounded-bl-lg flex bg-white bg-opacity-85 p-thin">
          <uv-icon-button
            class="text-gray-default"
            icon="download"
            :size="40"
            @click="onDownload"
          />
          <div class="w-0 border-l border-solid border-gray-light mx-1 my-2" />
          <uv-icon-button
            class="text-danger"
            icon="trash"
            :size="40"
            @click="onDelete"
          />
        </div>
        <MediaPreview
          v-if="media"
          ref="image"
          v-bind="$attrs"
          :type="type"
          :media="media"
        />
      </template>
      <template v-else>
        <div
          class="h-full flex items-center justify-center border-3 border-dashed border-gray-light p-3"
          v-bind="$attrs"
        >
          <div
            class="rounded-full bg-gray-lighter hover:bg-gray-default transition cursor-pointer p-5"
            @click="onPickFile"
          >
            <uv-icon
              class="text-white"
              icon="plus"
              :size="16"
            />
          </div>
        </div>
      </template>
    </template>
    <template v-else-if="state === 'busy'">
      <uv-loading
        class="m-auto"
        :size="40"
      />
    </template>
    <input
      ref="input"
      type="file"
      class="hidden"
      @change="onPickedFile"
    />
  </div>
</template>

<script lang="ts">
import { UvIcon, UvIconButton, UvLoading } from '@uniquevision/beluga-ui';
import { templateRef } from '@vueuse/core';
import { defineComponent, PropType, ref } from 'vue-demi';

import { Media } from '@/api/models/media';
import MediaPreview from '@/components/MediaPreview.vue';
import { useApi } from '@/hooks/use_api';

type State =
  'view' |
  'busy';

export default defineComponent({
  name: 'MediaUploader',
  components: {
    UvIcon,
    UvIconButton,
    UvLoading,
    MediaPreview,
  },
  inheritAttrs: false,
  props: {

    /**
     * メディアの種類
     */
    type: {
      type: String as PropType<'image' | 'video'>,
      required: true,
    },

    /**
     * v-model用
     */
    media: {
      type: Object as PropType<Media | null>,
      default: null,
    },
  },
  emits: [
    'update:media',
  ],
  setup(props, { emit }) {
    const api = useApi();
    const inputEl = templateRef<HTMLInputElement>('input');
    const state = ref<State>('view');

    function onDownload() {
      props.media?.download();
    }

    function onDelete() {
      emit('update:media', null);
    }

    function onPickFile() {
      inputEl.value?.click();
    }

    async function onPickedFile() {
      const currentInputEl = inputEl.value;

      if (!currentInputEl) {
        return;
      }

      const files = currentInputEl.files ?? [];

      if (files.length === 0) {
        return;
      }

      const file = files[0];
      currentInputEl.value = '';

      try {
        state.value = 'busy';
        const media = await api.postMedia(file);

        if (media) {
          emit('update:media', media);
        }
      } finally {
        state.value = 'view';
      }
    }

    return {
      onPickFile,
      onPickedFile,
      onDownload,
      onDelete,
      state,
    };
  },
});
</script>
