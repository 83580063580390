<template>
  <HighlightArea :highlight-id="card.uuid">
    <CardBoardFrame
      :card="card"
      :connected="connected"
      @click="$emit('click')"
    >
      <div class="flex flex-col space-y-1">
        <div
          class="h-12 line-clamp-2 whitespace-pre-wrap"
          v-html="card.tweetContent"
        />
        <MediaPreview
          class="self-stretch h-36"
          type="image"
          :media="card.twitterCardMedia"
        />
      </div>
    </CardBoardFrame>
  </HighlightArea>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { TweetCard } from '@/api/models/card';
import CardBoardFrame from '@/components/CardBoardFrame.vue';
import HighlightArea from '@/components/HighlightArea.vue';
import MediaPreview from '@/components/MediaPreview.vue';

/**
 * `TweetCard`の`Board`上の表示。
 */
export default defineComponent({
  name: 'TweetCardBoardView',
  components: {
    CardBoardFrame,
    MediaPreview,
    HighlightArea,
  },
  props: {

    /**
     * 表示するカード
     */
    card: {
      type: Object as PropType<TweetCard>,
      required: true,
    },

    /**
     * このカードが何かと接続しているかどうか
     */
    connected: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'click',
  ],
});
</script>
