/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';
import { inject, InjectionKey, provide } from 'vue-demi';

import { Campaign } from '@/api/models/campaign';
import { Card } from '@/api/models/card';
import { LocalMedia, Media } from '@/api/models/media';

import { useCurrentErrors } from './use_errors';

export interface CampaignApi {
  listCampaigns(): Promise<Campaign[] | undefined>
  getCampaign(campaignUuid: string): Promise<Campaign | undefined>
}

export interface CardsApi {
  postCards(campaignUuid: string, cards: Card[]): Promise<Card[] | undefined>;
  deleteCard(campaignUuid: string, cardUuid: string): Promise<void>;
}

export interface MediaApi {
  postMedia(file: File): Promise<Media | undefined>
}

export interface Api extends CampaignApi, CardsApi, MediaApi {

}

const API_INJECTION_KEY: InjectionKey<Api> = Symbol('api');

export class ApiError extends Error {

}

export function useRemoteApi() {
  // TODO: Implement the API using useAxios and provide it.
}

// eslint-disable-next-line object-curly-newline
export function useMockApi(target: Partial<Api> = {}): Api {
  const defaults: Partial<Api> = {
    async postMedia(file: File) {
      return new LocalMedia(file);
    },
  };

  const handler = {
    get: function(target: Partial<Api>, handler: keyof Api, receiver: any) {
      if (Object.keys(target).indexOf(handler) === -1) {
        throw new ApiError(`API：\`${handler}\`はこのテストで未実装。`);
      }

      return Reflect.get(target, handler, receiver);
    },
  };

  const api = new Proxy(Object.assign(defaults, target), handler) as Api;
  provide(API_INJECTION_KEY, api);

  return api;
}

export function useApi(): Api {
  const api = inject(API_INJECTION_KEY);
  const { reportError } = useCurrentErrors();

  if (!api) {
    throw new Error('API：`useApi`を使うには\`useRemoteApi\`または\`useMockApi\`を呼ぶ必要がある。');
  }

  const handler = {
    get: function(target: Api, handler: keyof Api, receiver: any) {
      try {
        return Reflect.get(target, handler, receiver);
      } catch (error) {
        if (error instanceof ApiError) {
          reportError(error.message);
        } else {
          reportError('エラーが発生しました。');
        }

        return _.noop;
      }
    },
  };

  return new Proxy(api, handler);
}
