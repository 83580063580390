
import { templateRef } from '@vueuse/core';
import { defineComponent, onMounted, PropType, watch } from 'vue';
import { GridItemData } from 'vue-grid-layout';

import { ROW_HEIGHT, ROW_MARGIN } from './Board';

/**
 * `Board`に置くグリッド要素。
 */
export default defineComponent({
  name: 'BoardItem',
  props: {

    /**
     * ボード全体のレイアウト
     */
    layout: {
      type: Object,
      required: true,
    },

    /**
     * グリッドの位置情報
     */
    modelValue: {
      type: Object as PropType<GridItemData>,
      required: true,
    },
  },
  emits: [
    'update:modelValue',
  ],
  setup(props, { emit }) {
    const content = templateRef('content');

    function getContentHeight(): number {
      if (!content.value) {
        return 0;
      }

      const height = content.value.clientHeight;

      /**
       * カード要素は何行を取ることを計算する。
       *
       * 基本的な計算は簡単。
       *
       * ```ts
       * height / ROW_HEIGHT
       * ```
       *
       * 正解に見えるが、カードの中でマージンがないため、その分も使う。
       *
       * ```ts
       * height / (ROW_HEIGHT + ROW_MARGIN)
       * ```
       *
       * それで行数が取れたが、最後のマージンも消えたから最初の高さに加える。
       *
       * ```ts
       * (height + ROW_MARGIN) / (ROW_HEIGHT + ROW_MARGIN)
       * ```
       *
       * 最後に、必ず指定された行内に表示するため、ceil()で次の行までスナップさせる。
       */
      return Math.ceil((height + ROW_MARGIN) / (ROW_HEIGHT + ROW_MARGIN));
    }

    function synchronizeContentHeight() {
      emit('update:modelValue', {
        ...props.modelValue,
        h: getContentHeight(),
      });
    }

    onMounted(() => {
      synchronizeContentHeight();
    });

    watch(() => props.layout, () => {
      synchronizeContentHeight();
    });
  },
});
