<template>
  <div class="h-full">
    <router-view />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue-demi';

export default defineComponent({
  name: 'Page',
});
</script>
