import { get, MaybeRef } from '@vueuse/shared';
import _ from 'lodash';
import { computed, inject, InjectionKey, provide, readonly, Ref, ref } from 'vue-demi';

export interface HighlightContext {

  /**
   * 現在ハイライトしている要素
   */
  readonly highlights: Ref<ReadonlySet<string>>

  /**
   * ハイライトが発生する時
   */
  onHighlight(id: string): void

  /**
   * ハイライトを解除する時
   */
  onUnhighlight(): void
}

const HIGHLIGHT_INJECTION_KEY: InjectionKey<HighlightContext> = Symbol('HighlightContext');

export type HighlightGraph = { start: string, end: string }[];

export function useHighlightDirectedGraph(graph: MaybeRef<HighlightGraph>): HighlightContext {
  const highlightGroupIds = computed(() => {
    // eslint-disable-next-line object-curly-newline
    const groups: Record<string, Set<string>> = {};

    for (const { start, end } of get(graph)) {
      groups[end] ??= new Set();
      groups[end].add(start);
    }

    return groups;
  });

  return useHighlightFunction(id => {
    const currentHighlightGroupIds = highlightGroupIds.value;
    const highlightedIds = new Set<string>([id]);
    const explorableIds = new Set<string>([id]);

    function prepareId(id: string) {
      if (!id) {
        return;
      }

      if (highlightedIds.has(id)) {
        return;
      }

      explorableIds.add(id);
    }

    while (explorableIds.size > 0) {
      const currentId: string = explorableIds.values().next().value;
      explorableIds.delete(currentId);
      highlightedIds.add(currentId);
      const group = currentHighlightGroupIds[currentId];

      if (group) {
        group.forEach(prepareId);
      }
    }

    return [...highlightedIds];
  });
}

export type Highlighter = (highlightId: string) => string[];

export function useHighlightFunction(highlighter: Highlighter): HighlightContext {
  const currentHightlightId = ref('');

  const context: HighlightContext = {
    highlights: computed(() => new Set(highlighter(currentHightlightId.value))),
    onHighlight: id => currentHightlightId.value = id,
    onUnhighlight: () => currentHightlightId.value = '',
  };

  provide(HIGHLIGHT_INJECTION_KEY, context);

  return context;
}

export function useHighlightIds(ids: string[]) {
  const context: HighlightContext = {
    highlights: readonly(ref(new Set(ids))),
    onHighlight: _.noop,
    onUnhighlight: _.noop,
  };

  provide(HIGHLIGHT_INJECTION_KEY, context);

  return context;
}

export function useCurrentHighlight() {
  return inject(HIGHLIGHT_INJECTION_KEY, {
    highlights: readonly(ref(new Set<string>())),
    onHighlight: _.noop,
    onUnhighlight: _.noop,
  });
}
