<template>
  <div
    class="flex flex-col bg-white rounded-lg shadow-default"
    :style="{ 'max-height': '90vh' }"
  >
    <div class="flex-shrink-0 text-white text-lg font-bold bg-secondary rounded-t-lg truncate px-4 py-2">
      {{ card ? card.cardNm : '新規作成' }}
    </div>
    <div class="h-full overflow-auto relative">
      <CurrentErrorsMessageBar class="absolute z-10" />
      <div class="p-6">
        <slot />
      </div>
    </div>
    <div class="relative flex justify-center space-x-5 px-1 py-2">
      <uv-button
        class="mb-1 w-40 text-lg"
        icon="check"
        @click="onSubmit"
      >
        保存
      </uv-button>
      <uv-button
        class="mb-1 w-40 text-lg"
        variant="white"
        @click="onCancel"
      >
        キャンセル
      </uv-button>
      <uv-dialog v-model="confirmDeletionDialog">
        <template #activator="{ open }">
          <div
            v-if="card"
            :class="[
              'absolute flex items-center right-0 top-0 bottom-0',
              'bg-danger text-white',
              'hover:bg-white hover:text-danger',
              'rounded-tl-lg rounded-br-lg mt-3 px-3',
              'cursor-pointer transition-all',
            ]"
            @click="open"
          >
            <uv-icon icon="trash" />
          </div>
        </template>
        <DeleteConfirmationDialog
          @delete="onConfirmDeletion"
          @cancel="onCancelDeletion"
        >
          <p>
            このカードを削除しますか？
          </p>
          <p class="text-danger">
            ※削除したカードは戻せません。
          </p>
        </DeleteConfirmationDialog>
      </uv-dialog>
    </div>
  </div>
</template>

<script lang="ts">
import { UvButton, UvDialog, UvIcon } from '@uniquevision/beluga-ui';
import _ from 'lodash';
import { defineComponent, PropType, ref } from 'vue-demi';

import { Card } from '@/api/models/card';
import CurrentErrorsMessageBar from '@/components/CurrentErrorsMessageBar.vue';

import DeleteConfirmationDialog from './DeleteConfirmationDialog.vue';

/**
 * それぞれのカード編集ダイヤログの共通スタイル。
 */
export default defineComponent({
  name: 'CardFormDialog',
  components: {
    UvButton,
    UvIcon,
    UvDialog,
    CurrentErrorsMessageBar,
    DeleteConfirmationDialog,
  },
  props: {

    /**
     * 編集されるカード
     */
    card: {
      type: Object as PropType<Card>,
      default: null,
    },

    /**
     * 保存する時
     */
    onSubmit: {
      type: Function,
      default: () => _.noop,
    },

    /**
     * 削除する時
     */
    onDelete: {
      type: Function,
      default: () => _.noop,
    },

    /**
     * キャンセルする時
     */
    onCancel: {
      type: Function,
      default: () => _.noop,
    },
  },
  setup(props) {
    const confirmDeletionDialog = ref(false);

    async function onConfirmDeletion() {
      props.onDelete();
      confirmDeletionDialog.value = false;
    }

    async function onCancelDeletion() {
      confirmDeletionDialog.value = false;
    }

    return {
      confirmDeletionDialog,
      onConfirmDeletion,
      onCancelDeletion,
    };
  },
});
</script>
