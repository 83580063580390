<template>
  <HighlightArea :highlight-id="button.uuid">
    <template #default="{ highlight }">
      <div
        class="relative"
        :class="{
          'border-twitter-blue text-twitter-blue': !highlight,
          'border-twitter-pink text-twitter-pink': highlight,
        }"
      >
        <div class="flex items-center justify-between rounded-2xl p-1 border-2 border-solid space-x-3">
          <div class="w-4" />
          <div class="truncate flex-grow text-center font-bold">
            {{ button.labelContent || '&nbsp;' }}
          </div>
          <template v-if="button.isUrl">
            <uv-icon
              class="flex-shrink-0"
              icon="link"
              :size="15"
            />
          </template>
          <template v-else>
            <div class="w-4" />
          </template>
        </div>
        <template v-if="button.isClick">
          <BoardConnectionSource
            class="absolute right-0 top-0 bottom-0 w-4 h-4 rounded-full -mr-5 my-auto flex items-center justify-end"
            :class="{
              'bg-twitter-blue': !highlight,
              'bg-twitter-pink': highlight,
            }"
            :button="button"
            :card="card"
          >
            <BoardConnectionPoint
              :connection-id="button.uuid"
              class="mr-1"
            />
          </BoardConnectionSource>
        </template>
      </div>
    </template>
  </HighlightArea>
</template>

<script lang="ts">
import { UvIcon } from '@uniquevision/beluga-ui';
import { defineComponent, PropType } from 'vue';

import { Button } from '@/api/models/button';
import { Card } from '@/api/models/card';
import BoardConnectionPoint from '@/components/BoardConnectionPoint.vue';
import BoardConnectionSource from '@/components/BoardConnectionSource.vue';
import HighlightArea from '@/components/HighlightArea.vue';

/**
 * `Button`の`Board`上の表示。
 */
export default defineComponent({
  name: 'ButtonBoardView',
  components: {
    UvIcon,
    HighlightArea,
    BoardConnectionPoint,
    BoardConnectionSource,
  },
  props: {

    /**
     * ボタンが入っているカード
     */
    card: {
      type: Object as PropType<Card>,
      required: true,
    },

    /**
     * 表示するボタン
     */
    button: {
      type: Object as PropType<Button>,
      required: true,
    },
  },
});
</script>
