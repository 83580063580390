/**
 * １行の高さ（px）
 *
 * 行は自動的に無限に伸びるから行数は設定できない。
 *
 * カードがちょうど行に入らない場合、このプロップはカードがどれほどみ出るかを決める。
 */
export const ROW_HEIGHT = 20;

/**
  * １列の幅（px）
  *
  * この項目が自然にカードの幅を決める。
  */
export const COLUMN_WIDTH = 95;

/**
  * カードの間の左右マージン（px）
  */
export const COLUMN_MARGIN = 20;

/**
  * カードの間の上下マージン（px）
  */
export const ROW_MARGIN = 20;
