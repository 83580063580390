<template>
  <div
    @mouseenter="trigger === 'hover' && onHighlight(highlightId)"
    @mouseleave="trigger === 'hover' && onUnhighlight()"
  >
    <slot :highlight="isHighlighted" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';

import { useCurrentHighlight } from '@/hooks/use_highlight';

/**
 * ホバーする時に入っている`useHighlight*`によりハイライトする。
 */
export default defineComponent({
  name: 'HighlightArea',
  props: {

    /**
     * `useHighlight*`で扱われるID
     */
    highlightId: {
      type: String,
      required: true,
    },

    /**
     * いつこのエリアのIDを起動するか
     */
    trigger: {
      type: String as PropType<'hover' | 'never'>,
      default: 'hover',
    },
  },
  setup(props) {
    const { highlights, onHighlight, onUnhighlight } = useCurrentHighlight();
    const isHighlighted = computed(() => highlights.value.has(props.highlightId));

    return {
      isHighlighted,
      onHighlight,
      onUnhighlight,
    };
  },
});
</script>
