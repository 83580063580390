
import { templateRef, useVModel } from '@vueuse/core';
import _ from 'lodash';
import { computed, defineComponent, PropType, readonly, ref } from 'vue-demi';
import { GridLayoutData } from 'vue-grid-layout';

import { Card } from '@/api/models/card';
import { Scenario, ScenarioConnection } from '@/api/models/scenario';
import { COLUMN_MARGIN, COLUMN_WIDTH, ROW_HEIGHT, ROW_MARGIN } from '@/components/Board';
import BoardCurve from '@/components/BoardCurve.vue';
import BoardItem from '@/components/BoardItem.vue';
import CardBoardView from '@/components/CardBoardView.vue';
import SmoothCurve from '@/components/SmoothCurve.vue';
import { useBoardConnection } from '@/hooks/use_board_connection';
import { useHighlightDirectedGraph } from '@/hooks/use_highlight';
import { useElementPositionInElement, useMousePositionInElement } from '@/hooks/use_position_in_element';

export default defineComponent({
  name: 'Board',
  components: {
    BoardItem,
    BoardCurve,
    SmoothCurve,
    CardBoardView,
  },
  props: {

    /**
     * 操作するシナリオ
     */
    modelValue: {
      type: Object as PropType<Scenario>,
      required: true,
    },

    /**
     * コラム数
     */
    columns: {
      type: Number,
      default: 40,
    },
  },
  emits: [
    'update:modelValue',
  ],
  setup(props, { emit }) {
    const boardId = readonly(ref(_.uniqueId('board')));
    const root = templateRef<HTMLDivElement>('root');
    const scenario = useVModel(props, 'modelValue', emit);
    const layout = computed<GridLayoutData>({
      get() {
        return scenario.value.map(card => {
          return {
            i: card.uuid,
            x: card.editAreaXCoordinate,
            y: card.editAreaYCoordinate,
            w: 2,
            h: 1,
          };
        });
      },
      set(data) {
        const itemsById = _.keyBy(data, item => item.i);

        for (const card of scenario.value as Card[]) {
          const item = itemsById[card.uuid];

          card.mutate({
            editAreaXCoordinate: item.x,
            editAreaYCoordinate: item.y,
          });
        }
      },
    });

    //
    // 接続の定義、作成、と削除
    //

    const connections = computed(() => scenario.value.getConnections());

    function onConnect(connection: ScenarioConnection) {
      connection.connect();
    }

    function onDisconnect(connection: ScenarioConnection) {
      connection.disconnect();
    }

    const { isConnecting, partialConnection } = useBoardConnection(onConnect);
    const partialConnectionStartId = computed(() => {
      const currentPartialConnection = partialConnection.value;

      if (!currentPartialConnection) {
        return null;
      }

      return `board-connection-point-${currentPartialConnection.button.uuid}`;
    });

    const partialConnectionStart = useElementPositionInElement(partialConnectionStartId, root);
    const partialConnectionEnd = useMousePositionInElement(root);

    //
    // ホバーする時のハイライト
    //

    const highlightConnections = computed(() => {
      return connections.value.flatMap(({ source, target, button }) => {
        return [
          {
            start: source.uuid,
            end: button.uuid,
          },
          {
            start: button.uuid,
            end: target.uuid,
          },
        ];
      });
    });

    useHighlightDirectedGraph(highlightConnections);

    return {
      COLUMN_WIDTH,
      ROW_HEIGHT,
      COLUMN_MARGIN,
      ROW_MARGIN,
      boardId,
      layout,
      scenario,
      connections,
      onDisconnect,
      isConnecting,
      partialConnectionStart,
      partialConnectionEnd,
    };
  },
});
