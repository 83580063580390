
import { defineComponent, PropType } from 'vue-demi';

import { PageCard } from '@/api/models/card';
import MediaPreview from '@/components/MediaPreview.vue';
import PageCardMediaGrid from '@/components/PageCardMediaGrid.vue';

export default defineComponent({
  name: 'PageCardMediaPreview',
  components: {
    MediaPreview,
    PageCardMediaGrid,
  },
  props: {
    card: {
      type: Object as PropType<PageCard>,
      required: true,
    },
  },
});
