
import { defineComponent, PropType, ref } from 'vue-demi';

import { PageCard } from '@/api/models/card';
import ButtonBoardView from '@/components/ButtonBoardView.vue';
import CardBoardFrame from '@/components/CardBoardFrame.vue';
import PageCardMediaPreview from '@/components/PageCardMediaPreview.vue';

/**
 * `PageCard`の`Board`上の表示。
 */
export default defineComponent({
  name: 'PageCardBoardView',
  components: {
    PageCardMediaPreview,
    CardBoardFrame,
    ButtonBoardView,
  },
  props: {

    /**
     * 表示するカード
     */
    card: {
      type: Object as PropType<PageCard>,
      required: true,
    },

    /**
     * このカードが何かと接続しているかどうか
     */
    connected: {
      type: Boolean,
    },
  },
  emits: [
    'click',
  ],
  setup() {
    const dialog = ref(false);

    return {
      dialog,
    };
  },
});
