import _ from 'lodash';

import { generate, registerGenerator } from '@/utilities/generate';
import { Kbns } from '@/utilities/kbn';

import { Button } from './button';
import { Card, PageCard, TweetCard } from './card';

export class Scenario<T extends Card = Card> extends Array<T> {
  getPages(): PageCard[] {
    const pages: PageCard[] = [];

    for (const card of this) {
      if (card.asPage()) {
        pages.push(card);
      }
    }

    return pages;
  }

  getTweets(): TweetCard[] {
    const tweets: TweetCard[] = [];

    for (const card of this) {
      if (card.asTweet()) {
        tweets.push(card);
      }
    }

    return tweets;
  }

  getConnections(): ScenarioConnection[] {
    const cardsByUuid = _.keyBy(this, card => card.uuid);
    const connections: ScenarioConnection[] = [];

    for (const source of this.getPages()) {
      for (const button of source.buttons) {
        if (button.isClick && button.targetCardUuid) {
          const target: Card | undefined = cardsByUuid[button.targetCardUuid];

          if (target) {
            connections.push(new ScenarioConnection(source, target, button));
          }
        }
      }
    }

    return connections;
  }

  getConnected(): Card[] {
    return this.getConnections().flatMap(connection => {
      return [connection.source, connection.target];
    });
  }
}

export class ScenarioConnection {
  constructor(
    readonly source: Card,
    readonly target: Card,
    readonly button: Button,
  ) {}

  connect() {
    this.button.targetCardUuid = this.target.uuid;
  }

  disconnect() {
    this.button.targetCardUuid = '';
  }
}

registerGenerator(
  Scenario,
  context => new Scenario<Card>(
    generate(PageCard, context).mutate({
      uuid: 'a',
      buttons: [
        generate(Button, context).mutate({
          buttonKbn: Kbns.BUTTON_KBN.CLICK,
          targetCardUuid: 'b',
          labelContent: 'ボタンA',
        }),
        ..._.range(9).map(index => generate(Button, context).mutate({
          lineNo: index + 1,
          labelContent: '',
        })),
      ],
    }),
    generate(PageCard, context).mutate({
      uuid: 'b',
      editAreaXCoordinate: 4,
      buttons: [
        generate(Button, context).mutate({
          buttonKbn: Kbns.BUTTON_KBN.CLICK,
          targetCardUuid: 'c',
          labelContent: 'ボタンB',
        }),
        ..._.range(9).map(index => generate(Button, context).mutate({
          lineNo: index + 1,
          labelContent: '',
        })),
      ],
    }),
    generate(TweetCard, context).mutate({
      uuid: 'c',
      editAreaXCoordinate: 8,
    }),
  ),
);
