import { RouteRecordRaw } from 'vue-router';

import CampaignPage from '@/pages/CampaignPage.vue';
import CampaignScenarioPage from '@/pages/CampaignScenarioPage.vue';
import Page from '@/pages/Page.vue';
import { Routes } from '@/utilities/route';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: Page,
    children: [
      {
        path: 'campaigns/:campaignUuid',
        component: CampaignPage,
        props: route => ({
          campaignUuid: route.params['campaignUuid'],
        }),
        children: [
          {
            name: Routes.CampaignScenario,
            path: 'scenario',
            component: CampaignScenarioPage,
          },
        ],
      },
    ],
  },
];
