import { get, MaybeElementRef, MaybeRef, useIntervalFn, useMouseInElement  } from '@vueuse/core';
import { computed, ref } from 'vue-demi';

/**
 * `useElementBounding`と`use*InElement`の組み合わせ。
 * ある要素の中で、子要素の座標が検知できる状態にするフック。
 *
 * 座標を監視するブラウザーAPIがないためポーリングで実装された。
 * そのため、子要素`ref`が受付できなくてIDを要求する。
 *
 * @param id 子要素のID
 * @param element 親要素自体
 * @returns 子要素の検知可能の座標
 */
export function useElementPositionInElement(
  id: MaybeRef<string | null>,
  element: MaybeRef<Element | null>,
) {
  const position = ref({
    x: Number.MIN_SAFE_INTEGER,
    y: Number.MIN_SAFE_INTEGER,
  });

  function getElementBounds(element: Element | null) {
    if (!element) {
      return {
        x: Number.MIN_SAFE_INTEGER,
        y: Number.MIN_SAFE_INTEGER,
      };
    }

    return element.getBoundingClientRect();
  }

  function getElementBoundsById(id: string | null) {
    if (!id) {
      return {
        x: Number.MIN_SAFE_INTEGER,
        y: Number.MIN_SAFE_INTEGER,
      };
    }

    return getElementBounds(document.getElementById(id));
  }

  useIntervalFn(() => {
    const { x: targetX, y: targetY } = getElementBoundsById(get(id));
    const { x: elementX, y: elementY } = getElementBounds(get(element));

    position.value = {
      x: targetX - elementX,
      y: targetY - elementY,
    };
  }, 25);

  return position;
}

/**
 * `vueuse`の`useMouseInElement`だが、要素内の座標を絞っている。
 *
 * @param element 親要素
 * @returns マウスの親要素内の座標
 */
export function useMousePositionInElement(element: MaybeElementRef) {
  const mouse = useMouseInElement(element);

  return computed(() => ({
    x: mouse.elementX.value,
    y: mouse.elementY.value,
  }));
}
