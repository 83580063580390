<template>
  <CardPreviewFrame>
    <!--
      @slot メディアの表示
    -->
    <slot name="media">
      <PageCardMediaPreview :card="card" />
    </slot>
    <div
      class="page-card-preview-content whitespace-pre-wrap break-all mx-2"
      v-html="card.textContent"
    />
    <div class="space-y-3 pb-4">
      <template v-if="card.usedButtons.length > 0">
        <CardButtonPreview
          v-for="button in card.usedButtons"
          :key="button.uuid"
        >
          <NewlinedText
            :text="button.labelContent || '&nbsp;'"
            :characters-per-line="19"
            :max-lines="2"
          />
        </CardButtonPreview>
      </template>
      <template v-else>
        <CardButtonPreview>
          ボタン
        </CardButtonPreview>
      </template>
    </div>
  </CardPreviewFrame>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue-demi';

import { PageCard } from '@/api/models/card';
import CardButtonPreview from '@/components/CardButtonPreview.vue';
import CardPreviewFrame from '@/components/CardPreviewFrame.vue';
import NewlinedText from '@/components/NewlinedText.vue';
import PageCardMediaPreview from '@/components/PageCardMediaPreview.vue';

export default defineComponent({
  name: 'PageCardPreview',
  components: {
    NewlinedText,
    PageCardMediaPreview,
    CardButtonPreview,
    CardPreviewFrame,
  },
  props: {

    /**
     * プレビューされるカード
     */
    card: {
      type: Object as PropType<PageCard>,
      required: true,
    },
  },
});
</script>

<style lang="scss">
.page-card-preview-content {
  a {
    color: theme('colors.secondary');
    text-decoration: underline;
  }
}
</style>
