/* eslint-disable object-curly-newline */

import { Exclude, Expose } from 'class-transformer';
import _ from 'lodash';
import * as zod from 'zod';

import { Button } from '@/api/models/button';
import { PageCard } from '@/api/models/card';
import { Media } from '@/api/models/media';
import { Form } from '@/utilities/form';
import { Kbn, Kbns } from '@/utilities/kbn';

class Fields {
  readonly cardKbn: Kbn<'CARD_KBN'> = Kbns.CARD_KBN.PAGE;

  cardNm = '';

  editAreaXCoordinate = 0;

  editAreaYCoordinate = 0;

  layoutKbn: Kbn<'PAGE_CARD_LAYOUT_KBN'> = Kbns.PAGE_CARD_LAYOUT_KBN.IMAGE_BUTTON_SINGLE;

  @Exclude()
  imageMediaAry: Media[] = [];

  @Expose()
  get imageMediaUuidAry() {
    return this.imageMediaAry.map(media => media.uuid);
  }

  @Exclude()
  videoMedia: Media | null = null;

  @Expose()
  get videoMediaUui() {
    return this.videoMedia?.uuid;
  }

  mediaImageRowCount = 1;

  mediaImageColumnCount = 1;

  textContent = '<p><br></p>';

  startFlag = false;

  readonly buttons = _.range(10).map(index => new Button().clone({
    lineNo: index,
  }));

  constructor(card?: PageCard) {
    if (card) {
      this.cardNm = card.cardNm;
      this.editAreaXCoordinate = card.editAreaXCoordinate;
      this.editAreaYCoordinate = card.editAreaYCoordinate;
      this.layoutKbn = card.layoutKbn;
      this.imageMediaAry = [...card.imageMediaAry];
      this.videoMedia = card.videoMedia;
      this.mediaImageColumnCount = card.mediaImageColumnCount;
      this.mediaImageRowCount = card.mediaImageRowCount;
      this.textContent = card.textContent;
      this.startFlag = card.startFlag;
      this.buttons = card.buttons.map(button => button.clone());
    }
  }
}

export class PageCardForm extends Form<Fields> {
  constructor(readonly original?: PageCard) {
    super();

    if (original) {
      this.fields = new Fields(original);
    }
  }

  readonly fields = new Fields();

  readonly schema: zod.Schema<Partial<Fields>> = zod.object({
    cardKbn: zod.nativeEnum(Kbns.CARD_KBN),
    cardNm: zod.string()
      .nonempty('カード名を指定してください。')
      .max(32),
    editAreaXCoordinate: zod.number(),
    editAreaYCoordinate: zod.number(),
    layoutKbn: zod.nativeEnum(Kbns.PAGE_CARD_LAYOUT_KBN),
    mediaImageRowCount: zod.number(),
    mediaImageColumnCount: zod.number(),
    textContent: zod.string(),
    startFlag: zod.boolean(),
    buttons: zod
      .array(zod.instanceof(Button))
      .length(10)
      .refine(buttons => buttons.filter(button => !button.isEmpty).length > 0, {
        message: '１つのボタンのラベルを入力してください。',
      }),
  });

  get card() {
    return new PageCard().mutate({
      uuid: this.original?.uuid,
      campaignUuid: this.original?.uuid,
      cardKbn: this.fields.cardKbn,
      cardNm: this.fields.cardNm,
      editAreaXCoordinate: this.fields.editAreaXCoordinate,
      editAreaYCoordinate: this.fields.editAreaYCoordinate,
      layoutKbn: this.fields.layoutKbn,
      imageMediaAry: this.fields.imageMediaAry,
      videoMedia: this.fields.videoMedia,
      mediaImageRowCount: this.fields.mediaImageRowCount,
      mediaImageColumnCount: this.fields.mediaImageColumnCount,
      textContent: this.fields.textContent,
      startFlag: this.fields.startFlag,
      buttons: this.fields.buttons,
    });
  }
}
