import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { registerGenerator } from '@/utilities/generate';
import { Kbn, Kbns } from '@/utilities/kbn';
import { Model } from '@/utilities/model';

export class Button extends Model<Button> {
  uuid = uuidv4();

  buttonKbn: Kbn<'BUTTON_KBN'> = Kbns.BUTTON_KBN.CLICK;

  lineNo = 0;

  labelContent = '';

  targetCardUuid = '';

  targetUrlContent = '';

  get isClick() {
    return this.buttonKbn === Kbns.BUTTON_KBN.CLICK;
  }

  get isUrl() {
    return this.buttonKbn === Kbns.BUTTON_KBN.URL;
  }

  get isEmpty() {
    return this.labelContent.trim() === '';
  }
}

registerGenerator(
  Button,
  ({ size }) => new Button().mutate({
    labelContent: _.repeat('僕のボタン', size),
    targetUrlContent: `https://${_.repeat('beluga.')}tokyo.jp`,
  }),
);
