
import { UvSelect, UvSelectOption } from '@uniquevision/beluga-ui';
import { useVModel } from '@vueuse/core';
import { defineComponent, PropType } from 'vue-demi';

import { Kbn } from '@/utilities/kbn';

export default defineComponent({
  name: 'TweetContentLinkSelect',
  components: {
    UvSelect,
    UvSelectOption,
  },
  props: {

    /**
     * v-model用
     */
    modelValue: {
      type: String as PropType<Kbn<'TWEET_CONTENT_LINK_KBN'>>,
      required: true,
    },
  },
  emits: [

    /**
     * v-model用
     */
    'update:modelValue',
  ],
  setup(props, { emit }) {
    const kbn = useVModel(props, 'modelValue', emit);

    return {
      kbn,
    };
  },
});
