
import { UvIcon } from '@uniquevision/beluga-ui';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AddBoardCardButton',
  components: {
    UvIcon,
  },
  props: {

    /**
     * ボードカード名
     *
     * ※ 長い名前に対応していない。３文字まで大丈夫。
     */
    name: {
      type: String,
      required: true,
    },

    /**
     * ボードカードアイコン
     */
    icon: {
      type: String,
      required: true,
    },
  },
});
