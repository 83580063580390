<template>
  <CardFormFieldRow :title="`ボタン${button.lineNo + 1}`">
    <div class="flex items-center space-x-1">
      <ButtonTypeSelect
        v-model:kbn="model.buttonKbn"
        class="w-32"
      />
      <div class="flex-grow">
        <uv-text-input
          v-model.trim="model.labelContent"
          :maxlength="100"
        />
      </div>
    </div>
    <template v-if="button.isUrl">
      <div class="flex items-center space-x-1">
        <div class="w-32 pl-2">
          URL
        </div>
        <div class="flex-grow">
          <uv-text-input
            v-model="model.targetUrlContent"
            :maxlength="1000"
          />
        </div>
      </div>
    </template>
  </CardFormFieldRow>
</template>

<script lang="ts">
import { UvTextInput } from '@uniquevision/beluga-ui';
import { useVModel } from '@vueuse/core';
import { defineComponent, PropType } from 'vue-demi';

import { Button } from '@/api/models/button';
import CardFormFieldRow from '@/components/CardFormFieldRow.vue';

import ButtonTypeSelect from './ButtonTypeSelect.vue';

export default defineComponent({
  name: 'ButtonCardFormFieldRow',
  components: {
    ButtonTypeSelect,
    CardFormFieldRow,
    UvTextInput,
  },
  props: {

    /**
     * v-model用
     */
    button: {
      type: Object as PropType<Button>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const model = useVModel(props, 'button', emit, {
      deep: true,
    });

    return {
      model,
    };
  },
});
</script>
