
import { UvDialog } from '@uniquevision/beluga-ui';
import { useVModel } from '@vueuse/core';
import { defineComponent, PropType, ref } from 'vue-demi';

import { PageCardForm } from '@/api/forms/page_card_form';
import { Scenario } from '@/api/models/scenario';

import AddBoardCardButton from './AddBoardCardButton.vue';
import PageCardFormDialog from './PageCardFormDialog.vue';

export default defineComponent({
  name: 'AddPageBoardCardButton',
  components: {
    AddBoardCardButton,
    PageCardFormDialog,
    UvDialog,
  },
  props: {
    modelValue: {
      type: Object as PropType<Scenario>,
      required: true,
    },
  },
  emits: [
    'update:modelValue',
  ],
  setup(props, { emit }) {
    const scenario = useVModel(props, 'modelValue', emit);
    const dialog = ref(false);

    async function onSubmit(form: PageCardForm) {
      scenario.value.push(form.card);
      dialog.value = false;
    }

    async function onCancel() {
      dialog.value = false;
    }

    return {
      scenario,
      dialog,
      onSubmit,
      onCancel,
    };
  },
});
