<template>
  <div
    class="w-full flex justify-between items-center text-white space-x-3 px-4 py-2"
    :class="
      {
        'bg-success': variant === 'success',
        'bg-danger': variant === 'error',
      }
    "
  >
    <div class="text-lg">
      <slot />
    </div>
    <uv-icon
      class="cursor-pointer"
      icon="close"
      :size="20"
      @click="$emit('close')"
    />
  </div>
</template>

<script lang="ts">
import { UvIcon } from '@uniquevision/beluga-ui';
import { defineComponent, PropType } from 'vue-demi';

export default defineComponent({
  name: 'MessageBar',
  components: {
    UvIcon,
  },
  props: {
    variant: {
      type: String as PropType<'success' | 'error'>,
      required: true,
    },
  },
  emits: [
    'close',
  ],
});
</script>
