<template>
  <div class="flex flex-col bg-blue-board h-full">
    <div class="flex justify-between items-center bg-blue-board select-none px-4 py-2 border-b border-gray-light">
      <div class="font-bold text-xl line-clamp-1">
        シナリオ入稿
      </div>
      <div class="flex items-center space-x-4">
        <div class="flex-shrink-0 flex items-center space-x-1 text-gray-default">
          <uv-icon icon="add" />
          <div class="font-bold text-xl line-clamp-1">
            カード追加
          </div>
        </div>
        <template v-if="campaign">
          <div class="flex space-x-3">
            <AddPageBoardCardButton v-model="campaign.cards" />
            <AddTweetBoardCardButton v-model="campaign.cards" />
          </div>
        </template>
      </div>
    </div>
    <div class="flex-grow overflow-auto">
      <Board
        v-model="campaign.cards"
        class="bg-blue-board"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { UvIcon } from '@uniquevision/beluga-ui';
import { defineComponent, reactive } from 'vue-demi';

import { Campaign } from '@/api/models/campaign';
import AddPageBoardCardButton from '@/components/AddPageBoardCardButton.vue';
import AddTweetBoardCardButton from '@/components/AddTweetBoardCardButton.vue';
import Board from '@/components/Board.vue';
import { useMockApi } from '@/hooks/use_api';

export default defineComponent({
  name: 'CampaignScenarioPage',
  components: {
    UvIcon,
    Board,
    AddPageBoardCardButton,
    AddTweetBoardCardButton,
  },
  setup() {
    useMockApi();
    const campaign = reactive(new Campaign());

    return {
      campaign,
    };
  },
});
</script>
