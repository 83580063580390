
import { UvDialog } from '@uniquevision/beluga-ui';
import { useVModel } from '@vueuse/core';
import { defineComponent, PropType, ref } from 'vue-demi';

import { TweetCardForm } from '@/api/forms/tweet_card_form';
import { Scenario } from '@/api/models/scenario';
import AddBoardCardButton from '@/components/AddBoardCardButton.vue';
import TweetCardFormDialog from '@/components/TweetCardFormDialog.vue';

export default defineComponent({
  name: 'AddTweetBoardCardButton',
  components: {
    AddBoardCardButton,
    TweetCardFormDialog,
    UvDialog,
  },
  props: {
    modelValue: {
      type: Object as PropType<Scenario>,
      required: true,
    },
  },
  emits: [
    'update:modelValue',
  ],
  setup(props, { emit }) {
    const scenario = useVModel(props, 'modelValue', emit);
    const dialog = ref(false);

    async function onSubmit(form: TweetCardForm) {
      scenario.value.push(form.card);
      dialog.value = false;
    }

    async function onCancel() {
      dialog.value = false;
    }

    return {
      scenario,
      dialog,
      onSubmit,
      onCancel,
    };
  },
});
