
import { UvTextInput } from '@uniquevision/beluga-ui';
import _ from 'lodash';
import { defineComponent, PropType, ref } from 'vue-demi';

import { PageCardForm } from '@/api/forms/page_card_form';
import { PageCard } from '@/api/models/card';
import { useErrors } from '@/hooks/use_errors';

import ButtonCardFormFieldRow from './ButtonCardFormFieldRow.vue';
import CardFormDialog from './CardFormDialog.vue';
import CardFormFieldBox from './CardFormFieldBox.vue';
import CardFormFieldDelimiter from './CardFormFieldDelimiter.vue';
import CardFormFieldRow from './CardFormFieldRow.vue';
import LinkText from './LinkText.vue';
import PageCardLayoutSelect from './PageCardLayoutSelect.vue';
import PageCardMediaUploader from './PageCardMediaUploader.vue';
import PageCardPreview from './PageCardPreview.vue';
import RichTextInput from './RichTextInput.vue';

export default defineComponent({
  name: 'PageCardFormDialog',
  components: {
    UvTextInput,
    CardFormDialog,
    CardFormFieldBox,
    CardFormFieldDelimiter,
    CardFormFieldRow,
    ButtonCardFormFieldRow,
    PageCardPreview,
    PageCardLayoutSelect,
    PageCardMediaUploader,
    RichTextInput,
    LinkText,
  },
  props: {

    /**
     * 編集するカード
     */
    card: {
      type: Object as PropType<PageCard>,
      default: undefined,
    },

    /**
     * 登録する時
     */
    onSubmit: {
      type: Function as PropType<(form: PageCardForm) => Promise<void>>,
      default: () => _.noop,
    },

    /**
     * 削除する時
     */
    onDelete: {
      type: Function as PropType<() => Promise<void>>,
      default: () => _.noop,
    },

    /**
     * キャンセルする時
     */
    onCancel: {
      type: Function as PropType<() => Promise<void>>,
      default: () => _.noop,
    },
  },
  setup(props) {
    const { clearErrors, reportFormErrors } = useErrors();
    const form = ref(new PageCardForm(props.card));

    function onValidateAndSubmit(form: PageCardForm) {
      const success = form.validate();
      clearErrors();

      if (success) {
        props.onSubmit(form);
      } else {
        reportFormErrors(form.latestError);
      }
    }

    return {
      form,
      onValidateAndSubmit,
    };
  },
});
