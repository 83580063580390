<template>
  <div class="flex align-top space-x-3">
    <div class="w-1/5 text-center my-1 space-y-1">
      <div
        v-if="title"
        class="text-lg font-bold text-gray-default"
      >
        {{ title }}
      </div>
      <div
        v-if="subtitle"
        class="text-gray-default text-sm"
      >
        {{ subtitle }}
      </div>
    </div>
    <div class="w-4/5 space-y-1">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue-demi';

export default defineComponent({
  name: 'CardFormFieldRow',
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
});
</script>
