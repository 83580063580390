<template>
  <svg class="w-full h-full">
    <path
      v-bind="$attrs"
      class="pointer-events-none"
      :d="curve"
      stroke="currentColor"
      stroke-width="1"
      fill="none"
    />
    <!-- もう１つの太くて影のような線を引く。クリックしやすくなる。-->
    <path
      v-bind="$attrs"
      :d="curve"
      stroke="transparent"
      stroke-width="12"
      fill="none"
      :style="{
        // レンダーされた線のみをイベントの対象にする。
        pointerEvents: 'visibleStroke',
      }"
    />
  </svg>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'SmoothCurve',
  inheritAttrs: false,
  props: {

    /**
     * 開始点
     */
    start: {
      type: Object as PropType<{
        x: number
        y: number
      }>,
      required: true,
    },

    /**
     * 終了点
     */
    end: {
      type: Object as PropType<{
        x: number
        y: number
      }>,
      required: true,
    },
  },
  setup(props) {
    const curve = computed(() => {
      const { x: startX, y: startY } = props.start;
      const { x: endX, y: endY } = props.end;
      const diffX = endX - startX;

      return [
        `M ${startX},${startY}`,
        `C ${startX + diffX * 0.4},${startY} ${endX - diffX * 0.4},${endY} ${endX},${endY}`,
      ].join(' ');
    });

    return {
      curve,
    };
  },
});
</script>
