
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BoardConnectionPoint',
  props: {

    /**
     * この接続点のID
     */
    connectionId: {
      type: String,
      required: true,
    },
  },
});
