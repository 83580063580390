
import _ from 'lodash';
import { computed, defineComponent } from 'vue-demi';

/**
 * 決まった改行仕様を持ったテキスト
 */
export default defineComponent({
  name: 'NewlinedText',
  props: {

    /**
     * 実際に表示する文字列
     */
    text: {
      type: String,
      required: true,
    },

    /**
     * 最大で一行で何文字を表示するか
     */
    charactersPerLine: {
      type: Number,
      required: true,
    },

    /**
     * 最大で何行を表示するか
     */
    maxLines: {
      type: Number,
      default: null,
    },
  },
  setup(props) {
    const lines = computed(() => _
      .chain(props.text.split(''))
      .chunk(props.charactersPerLine)
      .map(letters => letters.join(''))
      .value(),
    );

    const isOverflow = computed(() => lines.value.length > props.maxLines);

    const visibleLines = computed(() => {
      const visibleLines = lines.value.slice(0, props.maxLines);

      if (isOverflow.value) {
        const lastLine = visibleLines[visibleLines.length - 1];
        const ellipsedLastLine = lastLine.slice(0, lastLine.length - 1) + '…';
        visibleLines[visibleLines.length - 1] = ellipsedLastLine;
      }

      return visibleLines;
    });

    return {
      visibleLines,
    };
  },
});
