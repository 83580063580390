
import { useVModel } from '@vueuse/core';
import { defineComponent, PropType } from 'vue-demi';

import { PageCardForm } from '@/api/forms/page_card_form';
import MediaUploader from '@/components/MediaUploader.vue';
import PageCardMediaGrid from '@/components/PageCardMediaGrid.vue';

export default defineComponent({
  name: 'PageCardMediaUploader',
  components: {
    MediaUploader,
    PageCardMediaGrid,
  },
  props: {
    modelValue: {
      type: Object as PropType<PageCardForm>,
      required: true,
    },
  },
  emits: [
    'update:modelValue',
  ],
  setup(props, { emit }) {
    const form = useVModel(props, 'modelValue', emit);

    return {
      form,
    };
  },
});
