
import { defineComponent, PropType } from 'vue-demi';

import { Media } from '@/api/models/media';
import MediaPreview from '@/components/MediaPreview.vue';
import { Kbn } from '@/utilities/kbn';

export default defineComponent({
  name: 'TwitterCardPreview',
  components: {
    MediaPreview,
  },
  props: {

    /**
     * ツイッターカードのレイアウト
     */
    kbn: {
      type: String as PropType<Kbn<'TWITTER_CARD_LAYOUT_KBN'>>,
      required: true,
    },

    /**
     * ツイッターカードのタイトル
     */
    title: {
      type: String,
      required: true,
    },

    /**
     * ツイッターカードの詳細
     */
    description: {
      type: String,
      required: true,
    },

    /**
     * カード内で表示する画像
     */
    media: {
      type: Object as PropType<Media | null>,
      default: null,
    },
  },
});
